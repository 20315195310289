import { TickerDeleteModelComponent } from './../ticker-delete-model/ticker-delete-model.component';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { RapidApiService } from 'src/app/services/rapid-api.service';
import { TradeCloseModelComponent } from '../trade-close-model/trade-close-model.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TickerDetailsNoteModelComponent } from '../ticker-details-note-model/ticker-details-note-model.component';
import { ProductTickersComponent } from '../product-tickers/product-tickers.component';
import { TradeLegExpirationComponent } from '../trade-leg-expiration/trade-leg-expiration.component';

@Component({
  selector: 'app-ticker-details',
  templateUrl: './ticker-details.component.html',
  styleUrls: ['./ticker-details.component.scss'],
  providers: [ProductTickersComponent],
})
export class TickerDetailsComponent implements OnInit {
  iframeSrc!: SafeResourceUrl;
  currentRoute: any;
  public interval: any;
  activeToolDashboard = 'r6VAKez';
  slug: string;
  productSlug: string;
  toolSlug: string;
  stockCheck: number;
  row;
  allPosition = [];
  stockDetailRow;
  longPutPosition = [];
  weeklyPutPosition = [];
  weeklyCount: number;
  longCount: number;
  rows = [];
  user: any;
  role: any;
  stockDetails: any;
  symbol: string;
  tradeLogo: any;
  mode: string;
  closeMenuSubs: Subscription;
  askBid: any;
  timeOutPageTime = 0;
  isIntervalLoader = false;
  showAllLongRows = false;
  showAllWeeklyRows = false;
  isDropdownOpen = false;
  disableCloseTrade = false;
  @ViewChild('externalAnalyticsTable') table: any;
  parentData: any[];
  longParentData: any[];
  newWeeklyCount: any;
  newLongCount: any;
  allWeeklyData: any;
  allLongData: any;
  positionStatus: string;
  constructor(
    private location: Location,
    public modalController: ModalController,
    private menuService: commonRightMenuService,
    public storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private activatedRoute: ActivatedRoute,
    private menuController: MenuController,
    public alertController: AlertController,
    private toastServ: ToastService,
    private rapidService: RapidApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private productTickersComponent: ProductTickersComponent
  ) {
    this.slug = this.activatedRoute.snapshot.params.tickerSlug;
    this.productSlug = this.activatedRoute.snapshot.params.productSlug;
    this.toolSlug = this.activatedRoute.snapshot.params.toolSlug;
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {
    this.positionStatus = localStorage.getItem('positionStatus');
    this.getportpholioData(this.slug);
    if (this.slug) {
      this.getPositionData(this.slug);
    }
    this.closeMenuSubs = this.menuService.getCloseMenuSubject().subscribe((res) => {
      if (this.slug) {
        this.getPositionData(this.slug);
        if (!this.interval) {
          this.startInterval();
        }
      }
    });
    if (!this.interval) {
      this.startInterval();
    }
  }

  get displayedRowsLong() {
    if (this.showAllLongRows) {
      return this.longPutPosition;
    }
    const defaultRows = this.longPutPosition.slice(0, 3);
    const lastRow = this.longPutPosition[this.longPutPosition.length - 1];
    if (!defaultRows.includes(lastRow)) {
      defaultRows.push(lastRow);
    }
    return defaultRows;
  }

  get displayedRowsWeekly() {
    if (this.showAllWeeklyRows) {
      return this.weeklyPutPosition;
    }
    const defaultRows = this.weeklyPutPosition.slice(0, 3);
    const lastRow = this.weeklyPutPosition[this.weeklyPutPosition.length - 1];
    if (!defaultRows.includes(lastRow)) {
      defaultRows.push(lastRow);
    }
    return defaultRows;
  }

  toggleLongShowMore() {
    this.showAllLongRows = !this.showAllLongRows;
    if (!this.showAllLongRows) {
      let longData = this.longPutPosition.find((x) => x.treeStatus == 'expanded');

      this.longParentData = [
        ...this.longPutPosition.filter(
          (x) =>
            x.parentSlug == longData?.slug || x.parentSlug == '' || x.isTotalCumulativeProfitPrice
        ),
      ];
      this.longParentData.forEach((r) => {
        if (r.treeStatus === 'expanded') {
          r.treeStatus = 'collapsed';
        }
      });
      this.longParentData = this.longParentData?.slice(0, 3);
      const defaultLongRows = this.longParentData;

      const lastRow = this.longPutPosition[this.longPutPosition.length - 1];
      if (!defaultLongRows.includes(lastRow)) {
        defaultLongRows.push(lastRow);
      }
      this.longParentData = defaultLongRows;
    }
  }

  toggleWeeklyShowMore() {
    this.showAllWeeklyRows = !this.showAllWeeklyRows;
    if (!this.showAllWeeklyRows) {
      let weeklyData = this.weeklyPutPosition.find((x) => x.treeStatus == 'expanded');

      this.parentData = [
        ...this.weeklyPutPosition.filter(
          (x) =>
            x.parentSlug == weeklyData?.slug || x.parentSlug == '' || x.isTotalCumulativeProfitPrice
        ),
      ];
      this.parentData.forEach((r) => {
        if (r.treeStatus === 'expanded') {
          r.treeStatus = 'collapsed';
        }
      });
      this.parentData = this.parentData?.slice(0, 3);
      const defaultRows = this.parentData;

      const lastRow = this.weeklyPutPosition[this.weeklyPutPosition.length - 1];
      if (!defaultRows.includes(lastRow)) {
        defaultRows.push(lastRow);
      }
      this.parentData = defaultRows;
    }
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.checkforPosition();
    }, 300000);
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  async checkForPageTimeout() {
    if (this.timeOutPageTime >= 2) {
      this.destroyInterval();
      this.timeOutPageTime = 0;
      const alert = await this.alertController.create({
        cssClass: 'custom-alert-popup',
        subHeader: 'Your page has timed out. Please refresh the page and try again.',
        buttons: [
          {
            text: 'Reload',
            cssClass: 'delete-button',
            handler: () => {
              this.startInterval();
              this.getPositionData(this.slug);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'ghost-button',
          },
        ],
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }

  async checkforPosition() {
    const isInterval = await this.checkForPageTimeout();
    if (isInterval) {
      this.timeOutPageTime += 1;
      const positions = [...this.row?.long, ...this.row?.weekly];
      if (positions?.length) {
        let minExpiration = new Date(positions[0].expirationPeriod);
        let maxExpiration = new Date(positions[0].expirationPeriod);
        let minStrikeBought = this.getStrike(positions[0]);
        let maxStrikeBought = this.getStrike(positions[0]);
        positions.forEach((position) => {
          const expirationDate = new Date(position.expirationPeriod);
          if (expirationDate < minExpiration) {
            minExpiration = expirationDate;
          }
          if (expirationDate > maxExpiration) {
            maxExpiration = expirationDate;
          }
          const strikeBought = this.getStrike(position);
          if (strikeBought < minStrikeBought) {
            minStrikeBought = strikeBought;
          }
          if (strikeBought > maxStrikeBought) {
            maxStrikeBought = strikeBought;
          }
        });
        const payload = {
          symbol: this.stockDetailRow.symbol,
          type: 'Put',
          min_expiry: new Date(minExpiration).toISOString().substring(0, 10),
          max_expiry: new Date(maxExpiration).toISOString().substring(0, 10),
          min_strike: minStrikeBought,
          max_strike: maxStrikeBought,
        };
        // check and prepare payload accordingly
        this.rapidService.getExpirationDate(payload).subscribe(async (res) => {
          this.askBid = res;
          if (this.askBid?.length) {
            let isSync: any = false;
            for (const position of positions) {
              const askValue = this.askBid.find((ask) => {
                const expiration = moment(position?.expirationPeriod).format('YYYY-MM-DD');
                const strikePrice = position?.strikeBought || position?.strikeSold;
                const isSame = moment(expiration, 'YYYY-MM-DD').isSame(
                  moment(ask?.Expiration, 'YYYY-MM-DD')
                );
                if (isSame && strikePrice === ask.Strike) {
                  return ask;
                }
              });
              const askBidValue =
                position.positionType === 'weekly' ? askValue?.Ask : askValue?.Bid;
              if (askBidValue) {
                let payload = null;
                if (position?.positionStatus === 'open') {
                  const clone = {
                    tickerSymbol: this.stockDetailRow.symbol,
                    tickerSlug: this.slug,
                    positionType: position.positionType,
                    positionStatus: position.positionStatus,
                    optionDirection: '',
                    expirationPeriod: position.expirationPeriod,
                    numberOfContracts: position.numberOfContracts,
                    tradeProfitLoss: 0,
                    exitPremium: position.positionType === 'long' ? askValue?.Bid : askValue.Ask,
                    profitLossPercentage: 0,
                    underlyingStock: position.underlyingStock,
                  };
                  // for long position
                  if (position.positionType === 'long') {
                    payload = {
                      ...clone,
                      longPut: {
                        datePurchased: position.datePurchased,
                        premiumPaid: position.premiumPaid,
                        syntheticStrike: 0,
                        averagePremiumLoss: 0,
                        strikeBought: position.strikeBought,
                      },
                    };

                    const response = await this.toolsApi
                      .updatePosition(position.slug, payload, 'true')
                      .toPromise();
                    isSync = response ? true : false;
                  }
                  // for weekly position
                  if (position.positionType === 'weekly') {
                    payload = {
                      ...clone,
                      weeklyPut: {
                        dateSold: position.dateSold,
                        strikeSold: position.strikeSold,
                        premiumSold: position.premiumSold,
                        premiumAsPercentageOfStock: 0,
                        avgPremiumEarned: 0,
                      },
                    };
                    const response = await this.toolsApi
                      .updatePosition(position.slug, payload, 'true')
                      .toPromise();
                    isSync = response ? true : false;
                  }
                }
              }
            }
            if (isSync) {
              this.isIntervalLoader = true;
              setTimeout(() => {
                this.getPositionData(this.slug, 'true', 'false');
              }, 3000);
            }
          }
        });
      }
    }
  }

  checkForLoader(row) {
    let isLoader = false;
    const expiration: string = moment(row?.expirationPeriod).format('YYYY-MM-DD');
    const currentDate: string = moment().format('YYYY-MM-DD');
    const expirationDate: moment.Moment = moment(expiration, 'YYYY-MM-DD');
    const currentDateObj: moment.Moment = moment(currentDate, 'YYYY-MM-DD');
    if (
      row?.expirationPeriod &&
      expirationDate.isSameOrAfter(currentDateObj, 'day') &&
      row?.positionStatus === 'open'
    ) {
      isLoader = true;
    }
    return isLoader;
  }

  getportpholioData(data) {
    const { accountSize, weekly, long } = data;
    let weeklyLastVal = null;
    let longLastVal = null;
    let totalContracts = 0;
    let longStrikeBought = 0;
    let weeklyStrikeBought = 0;
    let openWeeklyPositions: any = [];
    let openLongPositions: any = [];
    if (weekly && weekly.length) {
      weekly.forEach((item) => {
        const weeklyData = item.legs[1];
        item.legs.forEach((leg) => {
          if (leg?.positionStatus == 'open') {
            openWeeklyPositions.push(leg);
          }
        });
      });
      weeklyLastVal =
        openWeeklyPositions && openWeeklyPositions.length
          ? openWeeklyPositions[openWeeklyPositions.length - 1]
          : {};
      /*    totalContracts = weekly.reduce(function (accumulator, currentValue) {
           return accumulator + currentValue.numberOfContracts;
         }, 0); */
    }

    if (long && long.length) {
      long.forEach((item) => {
        item.legs.forEach((leg) => {
          if (leg?.positionStatus == 'open') {
            openLongPositions.push(leg);
          }
        });
        longLastVal =
          openLongPositions && openLongPositions.length
            ? openLongPositions[openLongPositions.length - 1]
            : {};
        totalContracts += openLongPositions.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.numberOfContracts;
        }, 0);
      });
    }

    if (weeklyLastVal) {
      weeklyStrikeBought = weeklyLastVal.strikeSold;
    }
    if (longLastVal) {
      longStrikeBought = longLastVal.strikeBought;
    }
    const margin = (weeklyStrikeBought - longStrikeBought) * totalContracts * 100;
    this.row = {
      ...this.row,
      margin: margin || 0,
      portpholioRisk: accountSize && margin ? (margin / accountSize) * 100 : 0,
    };
  }

  getPositionData(slug, isLoader?, isTimerReset = 'true') {
    this.toolsApi.getPositionData(slug, isLoader).subscribe(
      (res: any) => {
        if (res.data && res.data?.position)
          this.row = {
            ...res.data.position,
          };
        if (res.data && res.data?.stockDetail)
          this.stockDetailRow = {
            ...res.data.stockDetail,
          };
        const nameValue = this.stockDetailRow.symbol;
        const dynamicUrl = `https://placehold.co/150@3x/77B84A/white?text=${nameValue}&font=roboto`;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
        this.getportpholioData(res.data.position);
        this.longPutPosition = [
          ...res.data.position.long,
          ...(res.data.position.long.length > 0
            ? [
                {
                  isTotalCumulativeProfitPrice: true,
                  cumulativeProfitLoss:
                    res?.data?.position?.long[res?.data?.position?.long.length - 1]
                      ?.cumulativeProfitLoss || 0,
                },
              ]
            : []),
        ];
        this.weeklyPutPosition = [];
        this.parentData = [];
        this.allWeeklyData = res.data.position.weekly;
        this.allLongData = res.data.position.long;
        if (res.data.position.weekly.length > 0) {
          res.data.position.weekly.forEach((item) => {
            const weeklyData = item.legs[1];
            const notes =
              item.legs[0]?.notes?.length > 0 ? item.legs[0]?.notes[0]?.description : '';
            item.legs.forEach((leg) => {
              if (leg.slug) {
                if (leg._id == null && item.legs.length > 2) {
                  const defaultLeg = {
                    _id: weeklyData._id,
                    parentSlug: leg?.parentSlug ? leg?.parentSlug : '',
                    isAssigned: false,
                    isSplit: true,
                    slug_history: [leg.slug],
                    type: weeklyData.type,
                    tickerMasterId: weeklyData.tickerMasterId,
                    positionStatus: weeklyData.positionStatus,
                    optionDirection: '',
                    expirationPeriod: weeklyData.expirationPeriod,
                    exitPremium: this.findExitPremium(item.legs),
                    numberOfContracts: this.findNumberOfContract(item.legs),
                    tradeProfitLoss: this.findPL(item.legs),
                    profitLossPercentage: this.findProfitLossPercentage(item.legs),
                    underlyingStock: weeklyData.underlyingStock,
                    positionType: 'weekly',
                    avgPremiumEarned: weeklyData.avgPremiumEarned,
                    dateSold: weeklyData.dateSold,
                    premiumAsPercentageOfStock: weeklyData.premiumAsPercentageOfStock,
                    strikeSold: this.findStrikeSold(item.legs),
                    premiumSold: this.findCredit(item.legs),
                    cumulativeProfitLoss: this.findCumulativeProfitLoss(item.legs),
                    slug: leg.slug,
                    __v: 0,
                    createdAt: weeklyData.createdAt,
                    updatedAt: weeklyData.updatedAt,
                    class: leg.class,
                    isHistoricalLeg: weeklyData.isHistorical,
                    noteDes: notes,
                    level: 1,
                  };
                  this.weeklyPutPosition.push(defaultLeg);
                } else {
                  if (item.legs.length == 2) {
                    if (leg.parentSlug == null) {
                      const defaultLeg = {
                        _id: weeklyData._id,
                        parentSlug: leg?.parentSlug ? leg?.parentSlug : '',
                        isAssigned: weeklyData.isAssigned,
                        isHistoricalLeg: weeklyData.isHistoricalLeg,
                        isSplit: weeklyData.isSplit,
                        slug_history: weeklyData.slug_history,
                        type: weeklyData.type,
                        tickerMasterId: weeklyData.tickerMasterId,
                        positionStatus: weeklyData.positionStatus,
                        optionDirection: weeklyData.optionDirection,
                        expirationPeriod: weeklyData.expirationPeriod,
                        exitPremium: weeklyData.exitPremium,
                        numberOfContracts: weeklyData.numberOfContracts,
                        tradeProfitLoss: weeklyData.tradeProfitLoss,
                        profitLossPercentage: weeklyData.profitLossPercentage,
                        underlyingStock: weeklyData.underlyingStock,
                        positionType: weeklyData.positionType,
                        avgPremiumEarned: weeklyData.avgPremiumEarned,
                        dateSold: weeklyData.dateSold,
                        premiumAsPercentageOfStock: weeklyData.premiumAsPercentageOfStock,
                        strikeSold: weeklyData.strikeSold,
                        premiumSold: weeklyData.premiumSold,
                        cumulativeProfitLoss: weeklyData.cumulativeProfitLoss,
                        slug: weeklyData.slug,
                        __v: weeklyData.__v,
                        createdAt: weeklyData.createdAt,
                        updatedAt: weeklyData.updatedAt,
                        class: leg.class,
                        level: weeklyData.level,
                        noteDes: notes,
                      };
                      this.weeklyPutPosition.push(defaultLeg);
                    }
                  } else {
                    const combinedLeg = { ...leg };
                    (combinedLeg.noteDes = notes), this.weeklyPutPosition.push(combinedLeg);
                  }
                }
              }
            });
          });

          for (let i = 0; i < this.weeklyPutPosition.length; i++) {
            const record = this.weeklyPutPosition[i];
            if (i == 0) {
              if (record.positionStatus == 'overdue') {
                record.tradeProfitLoss = 0;
                record.cumulativeProfitLoss = record.tradeProfitLoss;
              } else {
                record.cumulativeProfitLoss = record.tradeProfitLoss;
              }
            }
            if (record.parentSlug === '' && i !== 0) {
              let previousIndex = -1;
              for (let j = i - 1; j >= 0; j--) {
                if (this.weeklyPutPosition[j].parentSlug === '') {
                  previousIndex = j;
                  break;
                }
              }
              if (previousIndex !== -1) {
                if (record.positionStatus == 'overdue') {
                  record.tradeProfitLoss = 0;
                }
                const parentRecord = this.weeklyPutPosition[previousIndex];
                if (parentRecord) {
                  record.cumulativeProfitLoss =
                    parentRecord.cumulativeProfitLoss + record.tradeProfitLoss;
                }
              }
            }
          }

          this.parentData = this.weeklyPutPosition;

          this.weeklyPutPosition = this.weeklyPutPosition.map((row) => ({
            ...row,
          }));

          this.weeklyPutPosition = this.weeklyPutPosition.map((row) => {
            row.isSubData = row.class == 'child-row-custom-style'; // Add condition to mark as subdata
            return row;
          });

          this.weeklyCount = res.data.position.weekly.length;
          this.newWeeklyCount = res.data.position.weekly.length;
          let lastData = this.weeklyPutPosition.filter((x) => x.parentSlug == '');
          this.weeklyPutPosition = [
            ...this.weeklyPutPosition,
            ...(this.weeklyPutPosition.length > 0
              ? [
                  {
                    isTotalCumulativeProfitPrice: true,
                    cumulativeProfitLoss: lastData[lastData.length - 1]?.cumulativeProfitLoss || 0,
                    class: 'bold-row-custom-style',
                  },
                ]
              : []),
          ];
          this.parentData = this.weeklyPutPosition
            .filter((x) => x.parentSlug == '' || x.isTotalCumulativeProfitPrice)
            ?.slice(0, 3);
          const defaultRows = this.parentData;

          // const defaultRows = this.weeklyPutPosition.slice(0, 3);
          const lastRow = this.weeklyPutPosition[this.weeklyPutPosition.length - 1];
          if (!defaultRows.includes(lastRow)) {
            defaultRows.push(lastRow);
          }
          this.parentData = defaultRows;
        }

        this.longPutPosition = [];
        this.longParentData = [];
        if (res.data.position.long.length > 0) {
          res.data.position.long.forEach((item) => {
            const longData = item.legs[1];
            const notes =
              item.legs[0]?.notes?.length > 0 ? item.legs[0]?.notes[0]?.description : '';
            item.legs.forEach((leg) => {
              if (leg.slug) {
                if (leg._id == null && item.legs.length > 2) {
                  const defaultLeg = {
                    _id: longData._id,
                    parentSlug: leg?.parentSlug ? leg?.parentSlug : '',
                    isAssigned: false,
                    isSplit: true,
                    isHistoricalLeg: longData.isHistorical,
                    slug_history: [leg.slug],
                    type: longData.type,
                    tickerMasterId: longData.tickerMasterId,
                    positionStatus: longData.positionStatus,
                    optionDirection: '',
                    expirationPeriod: longData.expirationPeriod,
                    exitPremium: longData.exitPremium,
                    numberOfContracts: this.findNumberOfContract(item.legs),
                    tradeProfitLoss: this.findPL(item.legs),
                    profitLossPercentage: this.findProfitLossPercentage(item.legs),
                    underlyingStock: longData.underlyingStock,
                    positionType: 'long',
                    avgPremiumEarned: longData.avgPremiumEarned,
                    datePurchased: longData.datePurchased,
                    premiumAsPercentageOfStock: longData.premiumAsPercentageOfStock,
                    strikeBought: this.findStrikeBought(item.legs),
                    premiumPaid: this.findPremiumPaid(item.legs),
                    cumulativeProfitLoss: this.findCumulativeProfitLoss(item.legs),
                    slug: leg.slug,
                    __v: 0,
                    createdAt: longData.createdAt,
                    updatedAt: longData.updatedAt,
                    class: leg.class,
                    noteDes: notes,
                    level: 1,
                  };
                  this.longPutPosition.push(defaultLeg);
                } else {
                  if (item.legs.length == 2) {
                    if (leg.parentSlug == null) {
                      const defaultLeg = {
                        _id: longData._id,
                        parentSlug: leg?.parentSlug ? leg?.parentSlug : '',
                        isAssigned: longData.isAssigned,
                        isHistoricalLeg: longData.isHistoricalLeg,
                        isSplit: longData.isSplit,
                        slug_history: longData.slug_history,
                        type: longData.type,
                        tickerMasterId: longData.tickerMasterId,
                        positionStatus: longData.positionStatus,
                        optionDirection: longData.optionDirection,
                        expirationPeriod: longData.expirationPeriod,
                        exitPremium: longData.exitPremium,
                        numberOfContracts: longData.numberOfContracts,
                        tradeProfitLoss: longData.tradeProfitLoss,
                        profitLossPercentage: longData.profitLossPercentage,
                        underlyingStock: longData.underlyingStock,
                        positionType: longData.positionType,
                        avgPremiumEarned: longData.avgPremiumEarned,
                        datePurchased: longData.datePurchased,
                        premiumAsPercentageOfStock: longData.premiumAsPercentageOfStock,
                        strikeBought: longData.strikeBought,
                        premiumPaid: longData.premiumPaid,
                        cumulativeProfitLoss: longData.cumulativeProfitLoss,
                        slug: longData.slug,
                        __v: longData.__v,
                        createdAt: longData.createdAt,
                        updatedAt: longData.updatedAt,
                        noteDes: notes,
                        class: leg.class,
                        level: longData.level,
                      };
                      this.longPutPosition.push(defaultLeg);
                    }
                  } else {
                    const combinedLeg = { ...leg };
                    (combinedLeg.noteDes = notes), this.longPutPosition.push(combinedLeg);
                  }
                }
              }
            });
          });
          for (let i = 0; i < this.longPutPosition.length; i++) {
            const record = this.longPutPosition[i];
            if (i == 0) {
              if (record.positionStatus == 'overdue') {
                record.tradeProfitLoss = 0;
                record.cumulativeProfitLoss = record.tradeProfitLoss;
              } else {
                record.cumulativeProfitLoss = record.tradeProfitLoss;
              }
            }
            if (record.parentSlug === '' && i !== 0) {
              let previousIndex = -1;
              for (let j = i - 1; j >= 0; j--) {
                if (this.longPutPosition[j].parentSlug === '') {
                  previousIndex = j;
                  break;
                }
              }

              if (previousIndex !== -1) {
                const parentRecord = this.longPutPosition[previousIndex];
                if (record.positionStatus == 'overdue') {
                  record.tradeProfitLoss = 0;
                }
                if (parentRecord) {
                  record.cumulativeProfitLoss =
                    parentRecord.cumulativeProfitLoss + record.tradeProfitLoss;
                }
              }
            }
          }
          this.longParentData = this.longPutPosition;

          this.longPutPosition = this.longPutPosition.map((row) => ({
            ...row,
          }));

          this.longPutPosition = this.longPutPosition.map((row) => {
            row.isSubData = row.class == 'child-row-custom-style'; // Add condition to mark as subdata
            return row;
          });
          this.longCount = res.data.position.long.length;
          this.newLongCount = res.data.position.long.length;
          let lastLonData = this.longPutPosition.filter((x) => x.parentSlug == '');
          this.longParentData = this.longPutPosition.filter((x) => x.parentSlug == '');
          this.longPutPosition = [
            ...this.longPutPosition,
            ...(this.longPutPosition.length > 0
              ? [
                  {
                    isTotalCumulativeProfitPrice: true,
                    cumulativeProfitLoss:
                      lastLonData[lastLonData.length - 1]?.cumulativeProfitLoss || 0,
                    class: 'bold-row-custom-style',
                  },
                ]
              : []),
          ];

          this.longParentData = this.longPutPosition
            .filter((x) => x.parentSlug == '' || x.isTotalCumulativeProfitPrice)
            ?.slice(0, 3);
          const defaultLongRows = this.longParentData;

          // const defaultRows = this.longPutPosition.slice(0, 3);
          const lastLongRow = this.longPutPosition[this.longPutPosition.length - 1];
          if (!defaultLongRows.includes(lastLongRow)) {
            defaultLongRows.push(lastLongRow);
          }
          this.longParentData = defaultLongRows;
        }
        // turn of the interval loader
        this.setCloseTradeFlag();
        this.isIntervalLoader = false;
        // reset timer when getPositionData function is not calling from the checkforPosition() function
        if (isTimerReset === 'true') this.timeOutPageTime = 0;
      },
      (error) => {
        if (error || error?.message) {
          this.isIntervalLoader = false;
        }
      }
    );
  }
  findNumberOfContract(legs: any) {
    return legs.reduce((total, position) => {
      return total + (position.numberOfContracts ?? 0);
    }, 0);
  }
  findExitPremium(legs: any) {
    return legs?.reduce((total, position) => {
      return total + (position.exitPremium ?? 0);
    }, 0);
  }
  findCumulativeProfitLoss(legs: any) {
    return legs.reduce((total, position) => {
      return total + (parseFloat(position.cumulativeProfitLoss) || 0);
    }, 0);
  }
  findCredit(legs: any) {
    return legs.reduce((total, position) => {
      return total + (position.premiumSold ?? 0);
    }, 0);
  }
  findPremiumPaid(legs: any) {
    return legs.reduce((total, position) => {
      return total + (position.premiumPaid ?? 0);
    }, 0);
  }
  findPL(legs: any[]) {
    return legs.reduce((total, position) => {
      return total + (position.tradeProfitLoss ?? 0);
    }, 0);
  }
  findStrikeSold(legs: any) {
    return legs.reduce((total, position) => {
      return total + (position.strikeSold ?? 0) / (legs.length - 1);
    }, 0);
  }
  findStrikeBought(legs: any) {
    return legs.reduce((total, position) => {
      return total + (position.strikeBought ?? 0) / (legs.length - 1);
    }, 0);
  }
  findProfitLossPercentage(legs: any) {
    const result = legs.reduce((total, position) => {
      return (
        total +
        (!!position?.profitLossPercentage ? Number(position?.profitLossPercentage) ?? 0 : 0) /
          (legs.length - 1)
      );
    }, 0);

    return Number(result.toFixed(2));
  }

  findLegDetailsBySlug(slug): any {
    for (const item of this.weeklyPutPosition) {
      if (item.legs?.length > 0 || item.legs != null) {
        for (const leg of item?.legs) {
          if (leg.slug === slug) {
            return leg; // Return the matching leg details
          }
        }
      }
      return null;
    }
    return null;
  }

  setCloseTradeFlag() {
    let longPutPositionFilteredData;
    let weeklyPutPositionFilteredData;
    if (this.weeklyPutPosition && this.weeklyPutPosition.length > 0) {
      weeklyPutPositionFilteredData = this.weeklyPutPosition.filter(
        (ls) => ls.positionStatus && ls.positionStatus != undefined
      );
    }
    if (this.longPutPosition && this.longPutPosition.length > 0) {
      longPutPositionFilteredData = this.longPutPosition.filter(
        (ls) => ls.positionStatus && ls.positionStatus != undefined
      );
    }
    if (
      (longPutPositionFilteredData?.every((ls) => ls.positionStatus === 'closed') ||
        !longPutPositionFilteredData) &&
      (weeklyPutPositionFilteredData?.every((ls) => ls.positionStatus === 'closed') ||
        !weeklyPutPositionFilteredData)
    ) {
      this.disableCloseTrade = true; // Disable button
    } else {
      this.disableCloseTrade = false; // Enable button
    }
    if (!longPutPositionFilteredData && !weeklyPutPositionFilteredData) {
      this.disableCloseTrade = false;
    }
    if (this.positionStatus == 'closed') {
      this.disableCloseTrade = false;
    }
  }

  getStrike(position) {
    let strike = 0.0;
    if (position?.strikeSold) {
      strike = position?.strikeSold;
    } else if (position?.strikeBought) {
      strike = position?.strikeBought;
    }
    return strike;
  }

  back() {
    this.getPositionData(this.slug);
    // this.location.back();
    localStorage.setItem('positionStatus', '');
    this.router.navigate([
      `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}`,
    ]);
  }
  openRightMenu() {
    // destroy interval
    this.destroyInterval();
    const alertData = {
      componentType: 'alertComponent',
      slug: this.slug,
      symbol: this.stockDetailRow?.symbol,
      stockPrice: this.stockDetailRow?.stockPrice,
      todayPnL: this.stockDetailRow?.regularMarketChange,
    };
    this.menuService.openRightMenu(alertData); // Trigger the menu open event
  }

  openRightMenuNotes() {
    // destroy interval
    this.destroyInterval();
    const positionList = [...this.longPutPosition, ...this.weeklyPutPosition];
    const allData = [...this.allLongData, ...this.allWeeklyData];
    const notesData = {
      componentType: 'notesComponent',
      slug: this.slug,
      symbol: this.stockDetailRow?.symbol,
      positionList: positionList,
      allData: allData,
    };
    this.menuService.openRightMenu(notesData);
  }

  openRightMenuTraders() {
    // destroy interval
    this.destroyInterval();
    const notesData = {
      componentType: 'TradeHistoryComponent',
      slug: this.slug,
      symbol: this.stockDetailRow?.symbol,
      productSlug: this.productSlug,
      toolSlug: this.toolSlug,
    };
    this.menuService.openRightMenu(notesData);
  }

  async positionAddUpdate(type, mode, data?) {
    // destroy interval
    this.destroyInterval();
    this.mode = mode;
    const today = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    let positionProps: any = {
      mode,
      formType: type,
      symbol: this.stockDetailRow?.symbol,
      stockPrice: this.stockDetailRow?.stockPrice,
      slug: this.slug,
      componentType: 'positionComponent',
      isPositionExpired: false,
      todayDate: today,
      positionStatus: this.positionStatus,
    };
    const positionList = type === 'long' ? [...this.longPutPosition] : [...this.weeklyPutPosition];
    let parentSlug: any;
    let allData = type === 'long' ? this.allLongData : this.allWeeklyData;
    if (mode == 'edit') {
      parentSlug = allData.find((x) => x.legs.find((x) => x.slug == data.slug)).parentSlug;
      let slug: any = '';
      slug = data.slug;
      if (data.parentSlug == '') {
        data = positionList.filter((x) => x.parentSlug == data.slug);
        if (data.length > 2) {
          if (data.length == 2) {
            data = positionList[1];
          }
        } else if (data.length == 0) {
          data = positionList.find((x) => x.slug == slug);
        }
      }
      positionProps.positionList = positionList;
      positionProps.editData = data;
      positionProps.parentSlug = parentSlug;
      positionProps.isPositionExpired =
        data && data.expirationPeriod
          ? new Date(data.expirationPeriod).getTime() <= new Date().getTime()
          : true;
    } else {
      positionProps.positionList = positionList;
    }
    this.menuService.openRightMenu(positionProps);
  }

  calculateTotalLong(): number {
    let total = 0;
    if (this.row && this.row.long && this.row.long.length > 0) {
      total += this.row.long.reduce((acc, item) => acc + item.cumulativeProfitLoss, 0);
    }
    return total;
  }

  calculateTotalWeekly(): number {
    let total = 0;
    if (this.row && this.row.weekly && this.row.weekly.length > 0) {
      total += this.row.weekly.reduce((acc, item) => acc + item.cumulativeProfitLoss, 0);
    }
    return total;
  }

  calculatePnL(): number {
    let totalPnL = 0;
    let longPosition = this.longPutPosition?.length
      ? this.longPutPosition[this.longPutPosition.length - 1]?.cumulativeProfitLoss || 0
      : 0;
    let weeklyPosition = this.weeklyPutPosition?.length
      ? this.weeklyPutPosition[this.weeklyPutPosition.length - 1]?.cumulativeProfitLoss || 0
      : 0;
    totalPnL = longPosition + weeklyPosition;
    return totalPnL;
  }
  calculatePnLMargin(): number {
    let totalPnL = 0;
    totalPnL = this.calculatePnL();
    let margin = 0;
    margin = this.row?.margin;
    let returnMargin: any = 0;
    if (totalPnL > 0) {
      returnMargin = (totalPnL * 100) / margin;
      if (returnMargin == 'Infinity') {
        returnMargin = 0;
      }
    }
    return returnMargin;
  }

  getHeaderClassProfitLoss({ row }): any {
    return {
      'custom-cell-green': Number(row.profitLossPercentage) >= 0,
      'custom-cell-red': Number(row.profitLossPercentage) < 0,
    };
  }

  getHeaderClassTodayChange({ stockDetailRow }): any {
    return {
      'custom-green': Number(stockDetailRow?.regularMarketChange) >= 0,
      'custom-red': Number(stockDetailRow?.regularMarketChange) < 0,
      'custom-black': Number(stockDetailRow?.regularMarketChange) === 0,
    };
  }

  getHeaderClassPositionPnL({ row }): any {
    return {
      'custom-cell-green': Number(row.totalProfitLoss) >= 0,
      'custom-cell-red': Number(row.totalProfitLoss) < 0,
      'custom-cell-black': Number(row.totalProfitLoss) === 0,
    };
  }

  getClassForEarningDays({ stockDetailRow }) {
    return {
      // 'custom-cell-green': stockDetailRow?.daysDifference >= 45,
      // 'custom-cell-darkgoldenrod':
      //   stockDetailRow?.daysDifference >= 30 && stockDetailRow?.daysDifference < 45,
      // 'custom-cell-red': stockDetailRow?.daysDifference < 30,
      // 'custom-cell-yellow': stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate,

      'custom-cell-green': stockDetailRow?.daysDifference >= 45,
      'custom-cell-darkgoldenrod':
        stockDetailRow?.daysDifference >= 30 && stockDetailRow?.daysDifference < 45,
      'custom-cell-red': stockDetailRow?.daysDifference < 30,
      'custom-cell-black': stockDetailRow?.status == 'closed',
    };
  }

  getColorBasedOnStatus(row): string {
    return row === 'open' ? 'darkgoldenrod' : ''; // Set the desired color for 'open' status
  }

  async openConfirmationDialog(row, type) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure you want to delete this Leg?',
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.onTrash(row, type);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
      ],
    });
    await alert.present();
  }

  onTrash(row, type) {
    let data = [];
    data = type === 'long' ? this.allLongData : this.allWeeklyData;
    if (row.class == 'bold-row-custom-style' || row.class == 'assigned-row-custom-style') {
      row.slug = data.find((x) => x.legs.find((x) => x.slug == row.slug)).parentSlug;
    }
    let notesSlug = data.find((x) => x.legs.find((x) => x.slug == row.slug))?.legs[0]?.notes[0]
      ?.slug;

    this.toolsApi.deleteLegs(row.slug, type).subscribe((response: any) => {
      if (!!row.noteDes) {
        this.toolsApi.deleteNote(notesSlug).subscribe((response: any) => {});
      }
      this.toastServ.presentToast(response.message, 'success');
      this.mode = 'delete';
      this.getPositionData(this.slug);
    });
  }

  ngOnDestroy() {
    this.destroyInterval();
    if (this.closeMenuSubs) {
      this.closeMenuSubs.unsubscribe();
    }
  }
  toggleDropdown(event: Event, row: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  async showTradeDelete() {
    this.isDropdownOpen = !this.isDropdownOpen;
    const modal = await this.modalController.create({
      cssClass: 'delete-trade small-modal',
      component: TradeCloseModelComponent,
      componentProps: { toolSlug: this.toolSlug, productSlug: this.productSlug, slug: this.slug },
    });

    modal.onDidDismiss().then(({ data }) => {
      this.productTickersComponent.onDeleteTrade();
    });
    return await modal.present();
  }

  accountSizeRedirect() {
    this.router.navigate([`/${this.role}/settings`], {
      queryParams: { tab: 'account-size' },
    });
  }
  async showTickerAddDetails(type, mode, row) {
    const positionList = type === 'long' ? [...this.longPutPosition] : [...this.weeklyPutPosition];
    let parentSlug: any;
    let data = type === 'long' ? this.allLongData : this.allWeeklyData;
    // if (row.class == 'bold-row-custom-style' || row.class == 'assigned-row-custom-style') {
    //   parentSlug = data.find((x) => x.legs.find((x) => x.slug == row.slug)).parentSlug;
    // }
    parentSlug = data.find((x) => x.legs.find((x) => x.slug == row.slug)).parentSlug;
    let slug: any = '';
    slug = row.slug;
    // if (row.parentSlug == '') {
    //   row = positionListdeleteNotes.filter((x) => x.parentSlug == row.slug);
    //   if (row.length >= 2) {
    //     if (row.length == 2) {
    //       row = row[1];
    //     }
    //   } else if (row.length == 0) {
    //     row = positionList.find((x) => x.slug == slug);
    //   }
    // }
    const modal = await this.modalController.create({
      cssClass: 'add-ticker-details small-modal',
      component: TickerDetailsNoteModelComponent,
      componentProps: {
        data: row,
        type: type,
        mode: mode,
        toolSlug: this.toolSlug,
        productSlug: this.productSlug,
        parentSlug: parentSlug,
        slug: this.slug,
      },
    });

    modal.onDidDismiss().then(({ data }) => {});
    return await modal.present();
  }
  isRowClass = (event) => {
    if (event.class) {
      if (event.class == 'child-row-custom-style') {
        return {
          'child-row-custom-style': true,
        };
      }
      if (event.class == 'bold-row-custom-style') {
        return {
          'bold-row-custom-style': true,
        };
      }
      if (event.class == 'assigned-row-custom-style') {
        return {
          'assigned-row-custom-style': true,
        };
      }
      if (event.class == 'split-row-custom-style') {
        return {
          'split-row-custom-style': true,
        };
      }
      if (event.class == 'assigned-split-row-custom-style') {
        return {
          'assigned-split-row-custom-style': true,
        };
      }
    }
  };
  onTreeActionWeekly(event: any) {
    if (event.type === 'click') {
      const row = event.row;
      row.treeStatus = row.treeStatus === 'expanded' ? 'collapsed' : 'expanded';
      if (row.treeStatus === 'expanded') {
        this.weeklyPutPosition.forEach((r) => {
          if (r !== row && r.treeStatus === 'expanded') {
            r.treeStatus = 'collapsed';
          }
        });
      }
      this.parentData = [];

      this.weeklyPutPosition = [...this.weeklyPutPosition];
      this.parentData = this.weeklyPutPosition;
      let weeklyData = this.weeklyPutPosition.find((x) => x.treeStatus == 'expanded');

      this.parentData = [
        ...this.weeklyPutPosition.filter(
          (x) =>
            x.parentSlug == weeklyData?.slug || x.parentSlug == '' || x.isTotalCumulativeProfitPrice
        ),
      ];
      //added to show child data whith the parent
      let count = 0;
      let childData: any;

      // const defaultRows = this.weeklyPutPosition.slice(0, 3);

      childData = this.weeklyPutPosition.filter((x) => x.parentSlug == weeklyData?.slug);
      if (childData.length > 1) {
        count = childData.length + 3;
      } else {
        count = 3;
      }
      // this.parentData = this.parentData?.slice(0, count);

      this.parentData = this.parentData?.slice(0, count);
      const defaultRows = this.parentData;

      const lastRow = this.weeklyPutPosition[this.weeklyPutPosition.length - 1];
      if (!defaultRows.includes(lastRow)) {
        defaultRows.push(lastRow);
      }
      this.parentData = defaultRows;
      // if ((this.weeklyCount < 3 || this.weeklyCount == 3) && weeklyData != null) {
      //   this.weeklyCount = count + 1;
      // } else {
      //   this.weeklyCount = this.newWeeklyCount;
      // }
      this.cd.detectChanges();
    }
  }

  onTreeActionLong(event: any) {
    if (event.type === 'click') {
      const row = event.row;
      row.treeStatus = row.treeStatus === 'expanded' ? 'collapsed' : 'expanded';
      if (row.treeStatus === 'expanded') {
        this.longPutPosition.forEach((r) => {
          if (r !== row && r.treeStatus === 'expanded') {
            r.treeStatus = 'collapsed';
          }
        });
      }
      this.longParentData = [];

      this.longPutPosition = [...this.longPutPosition];
      this.longParentData = this.longPutPosition;
      let longData = this.longPutPosition.find((x) => x.treeStatus == 'expanded');

      this.longParentData = [
        ...this.longPutPosition.filter(
          (x) =>
            x.parentSlug == longData?.slug || x.parentSlug == '' || x.isTotalCumulativeProfitPrice
        ),
      ];
      //added to show child data whith the parent
      let count = 0;
      let childData: any;

      // const defaultRows = this.longPutPosition.slice(0, 3);

      childData = this.longPutPosition.filter((x) => x.parentSlug == longData?.slug);
      if (childData.length > 1) {
        count = childData.length + 3;
      } else {
        count = 3;
      }
      // this.longParentData = this.longParentData?.slice(0, count);

      this.longParentData = this.longParentData?.slice(0, count);
      const defaultRows = this.longParentData;

      const lastRow = this.longPutPosition[this.longPutPosition.length - 1];
      if (!defaultRows.includes(lastRow)) {
        defaultRows.push(lastRow);
      }
      this.longParentData = defaultRows;
      // if ((this.longCount < 3 || this.longCount == 3) && longData != null) {
      //   this.longCount = count + 1;
      // } else {
      //   this.longCount = this.newlongCount;
      // }
      this.cd.detectChanges();
    }
  }

  async showTradeDeleteConfirmation() {
    const modal = await this.modalController.create({
      cssClass: 'delete-trade small-modal',
      component: TickerDeleteModelComponent,
      componentProps: {
        id: this.row[0]._id,
        toolSlug: this.toolSlug,
        productSlug: this.productSlug,
        slug: this.slug,
      },
    });

    modal.onDidDismiss().then(() => {
      this.productTickersComponent.onDeleteTrade();
    });

    return await modal.present();
  }
  async onOverdueClick(type, data) {
    if (data.class !== 'child-row-custom-style') {
      let parentSlug: any;
      let newData = type === 'long' ? this.allLongData : this.allWeeklyData;
      parentSlug = newData.find((x) => x.legs.find((x) => x.slug == data.slug)).parentSlug;
      const positionList =
        type === 'long' ? [...this.longPutPosition] : [...this.weeklyPutPosition];
      const modal = await this.modalController.create({
        cssClass: 'delete-trade small-modal',
        component: TradeLegExpirationComponent,
        componentProps: {
          data: data,
          type: type,
          toolSlug: this.toolSlug,
          productSlug: this.productSlug,
          slug: this.slug,
          positionList: positionList,
          stockDetailRow: this.stockDetailRow,
          parentSlug: parentSlug,
        },
      });

      modal.onDidDismiss().then(({ data }) => {
        // this.productTickersComponent.onDeleteTrade();
      });
      return await modal.present();
    } else {
      return;
    }
  }
}

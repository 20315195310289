import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TradeTrackerModule } from './trade-trackers/trade-trackers.module';
import { ToolDashboardModule } from './tool-dashboard/tool-dashboard.module';
import { WatchListModule } from './watch-list/watch-list.module';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { SetupScannerModule } from './setup-scanner/setup-scanner.module';
import { ActiveAlertModule } from './alerts-details/active-alerts/active-alert.module';

const routes: Routes = [
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/:id',
    loadChildren: () => ToolDashboardModule,
  },
  {
    path: 'dashboard/:id/ticker-symbol/:symbolName',
    loadChildren: () => ToolDashboardModule,
  },
  {
    path: 'trade-trackers/:toolSlug',
    redirectTo: 'trade-trackers',
    pathMatch: 'full',
  },
  {
    path: 'trade-trackers/:toolSlug',
    loadChildren: () => TradeTrackerModule,
  },
  {
    path: 'watchList/:id',
    loadChildren: () => WatchListModule,
  },
  {
    path: 'setup-scanner/:id',
    loadChildren: () => SetupScannerModule,
  },
  {
    path: 'alerts/:id',
    loadChildren: () => ActiveAlertModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ToolsRoutingModule {}

<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ol class="cd-breadcrumb custom-separator custom-icons">
      <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
      <li>
        <a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools Dashboard</a>
      </li>
      <li class="current" *ngIf="selectedLookup && selectedLookup?.symbol">
        {{ selectedLookup?.symbol | uppercase }}
      </li>
    </ol>
    <ion-row>
      <h1 class="heading-title">Welcome {{ username }}, Good to See you!</h1>
    </ion-row>

    <ion-row class="tracker-details-row search-section">
      <ion-col size="6" class="tracker-details-col">
        <div class="loading-spinner" *ngIf="isLoadingSearch"></div>
        <ion-searchbar
          class="searchbar-green"
          search-icon="search-outline"
          debounce="1000"
          id="search"
          placeholder="Type a ticker symbol here..."
          (ionChange)="onSearchLookupNew($event)"
          (keydown)="handleKeyDown($event)"
          [value]="
            selectedLookup.symbol && selectedLookup.name
              ? selectedLookup.symbol + ' - ' + selectedLookup.name
              : ''
          "
        >
        </ion-searchbar>
        <div
          class="taglist-search"
          *ngIf="
            !isLoading &&
            lookupListVisibility &&
            !selectedLookup?.symbol &&
            searchResult &&
            searchResult.length > 0
          "
        >
          <span class="close" (click)="hideLookupList()">&#10005;</span>
          <p
            *ngFor="let item of searchResult; let i = index"
            (click)="addLookup(item.symbol)"
            [class.selected]="i === highlightedIndex"
            tabindex="0"
          >
            {{ item.symbol }} - {{ item.name }}
          </p>
        </div>
      </ion-col>
    </ion-row>
    <div>
      <ion-row class="tracker-details-row main-option">
        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-set">
                    <img class="product-image" src="/assets/icon/trade-trecker.png" />
                    <div class="product-description">
                      <div class="product-name">Trade Tracker</div>
                      <span class="product-desc">
                        Keep track of your trades with our Trade Tracker, offering real-time
                        insights.
                      </span>
                      <ion-button
                        class="mobile-btn"
                        fill="clear"
                        color="primary"
                        (click)="onTradeTrackerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button
                        class="desktop-btn"
                        fill="clear"
                        color="primary"
                        (click)="onTradeTrackerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-set">
                    <img class="product-image" src="/assets/icon/watchlist.png" />
                    <div class="product-description">
                      <div class="product-name">Watchlist</div>
                      <span class="product-desc">
                        Stay ahead of the market with Watchlist feature, tailored to monitor
                        favorite stocks.
                      </span>
                      <ion-button
                        class="mobile-btn"
                        fill="clear"
                        color="primary"
                        (click)="onWatchListView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button
                        class="desktop-btn"
                        fill="clear"
                        color="primary"
                        (click)="onWatchListView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container tracker-details">
            <div class="custom-card custom-card-block border-bottom">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-set">
                    <img class="product-image" src="/assets/icon/scanner.png" />
                    <div class="product-description">
                      <div class="product-name">Setup Screener</div>
                      <span class="product-desc">
                        Discover the power of our comprehensive Stock Scanner, designed to sift.
                      </span>
                      <ion-button
                        class="mobile-btn"
                        fill="clear"
                        color="primary"
                        (click)="onScannerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                  <div class="product-positions-container">
                    <div class="product-view-positions">
                      <ion-button
                        class="desktop-btn"
                        fill="clear"
                        color="primary"
                        (click)="onScannerView()"
                        >View more</ion-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="tracker-details-row main-section">
        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-full">
                    <div class="climate-name">Treasure Hunting Climate</div>
                    <div
                      class="product-description climate-details"
                      *ngFor="let item of climateData"
                    >
                      <div class="video-wrapper">
                        <iframe
                          [src]="iframeSrc"
                          width="560"
                          height="340"
                          frameborder="1"
                          allowfullscreen="allowfullscreen"
                          data-mce-fragment="1"
                        ></iframe>
                      </div>
                      <span class="product-desc-text">
                        <span class="text-desc">{{ item?.description | slice : 0 : 75 }}...</span>
                        <span (click)="showClimateDetails()" class="btn-readmor">Read more</span>
                      </span>
                    </div>
                  </div>
                  <!-- <div class="Under-construction"> -->
                  <!-- <div class="inactive-custom"> -->
                  <!-- <ion-icon name="construct-outline"></ion-icon> -->
                  <!-- <h1>Under construction</h1> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-full">
                    <div class="climate-name">Recent Alerts</div>
                    <div
                      class="empty-alert-list product-description-container-center"
                      *ngIf="alertAllData?.length == 0"
                    >
                      <ion-icon name="notifications-off-outline"></ion-icon>
                      <span>No recent alerts</span>
                    </div>

                    <div class="product-description climate-details" *ngIf="alertAllData?.length > 0">
                      <div class="recents-alerts-wrap">
                        <div
                          [ngClass]="
                            checkIfDateIsPastOrToday(alertData.triggerDate) == false
                              ? 'alert-block alert-class'
                              : 'alert-block past-alert-class'
                          "
                          *ngFor="let alertData of alertAllData"
                          (click)="showRecentsAlerts(alertData)"
                        >
                          <div class="text-alerts" *ngIf="alertData.alertType == 'stock_price'">
                            <div class="text-stock">Stock Price Alert</div>
                            <div
                              [ngClass]="
                                checkIfDateIsPastOrToday(alertData.triggerDate) == false
                                  ? 'price-stock '
                                  : 'past-price-stock'
                              "
                            >
                              Price crossed
                              <span class="green-text">${{ alertData.alertPrice }}.</span>
                            </div>
                          </div>
                          <div class="text-alerts" *ngIf="alertData.alertType == 'on_date'">
                            <div class="text-stock">Date/Time Alert</div>
                            <div class="price-stock">Alert Triggered</div>
                          </div>
                          <div class="symbol-stock">
                            <div class="symbol-img-name">
                              <img
                                class="size-css"
                                src="{{ alertData.logoUrl }}"
                                aria-placeholder="stockimage"
                              /><span>{{ alertData.symbol }}</span>
                              <span>
                                <svg
                                  width="14"
                                  height="13"
                                  viewBox="0 0 14 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.9 0C12.457 0 12.9911 0.212597 13.3849 0.591021C13.7788 0.969446 14 1.4827 14 2.01787V8.74411C14 9.27929 13.7788 9.79254 13.3849 10.171C12.9911 10.5494 12.457 10.762 11.9 10.762H3.7331L1.4 12.4435C0.8232 12.8592 0 12.4637 0 11.7709V2.01787C0 1.4827 0.221249 0.969446 0.615076 0.591021C1.0089 0.212597 1.54305 0 2.1 0H11.9ZM11.9 1.34525H2.1C1.91435 1.34525 1.7363 1.41611 1.60503 1.54226C1.47375 1.6684 1.4 1.83948 1.4 2.01787V10.762L2.8931 9.68579C3.13543 9.51115 3.43018 9.41674 3.7331 9.41674H11.9C12.0857 9.41674 12.2637 9.34587 12.395 9.21973C12.5263 9.09359 12.6 8.92251 12.6 8.74411V2.01787C12.6 1.83948 12.5263 1.6684 12.395 1.54226C12.2637 1.41611 12.0857 1.34525 11.9 1.34525ZM6.3 6.05362C6.47842 6.05381 6.65002 6.11945 6.77976 6.23714C6.90949 6.35483 6.98756 6.51568 6.99802 6.68682C7.00848 6.85797 6.95053 7.02649 6.83601 7.15795C6.7215 7.28942 6.55907 7.37391 6.3819 7.39416L6.3 7.39887H4.2C4.02158 7.39868 3.84998 7.33303 3.72024 7.21534C3.59051 7.09765 3.51244 6.93681 3.50198 6.76566C3.49152 6.59452 3.54947 6.426 3.66399 6.29453C3.7785 6.16306 3.94093 6.07857 4.1181 6.05833L4.2 6.05362H6.3ZM9.8 3.36312C9.98565 3.36312 10.1637 3.43399 10.295 3.56013C10.4263 3.68627 10.5 3.85735 10.5 4.03575C10.5 4.21414 10.4263 4.38522 10.295 4.51136C10.1637 4.6375 9.98565 4.70837 9.8 4.70837H4.2C4.01435 4.70837 3.8363 4.6375 3.70503 4.51136C3.57375 4.38522 3.5 4.21414 3.5 4.03575C3.5 3.85735 3.57375 3.68627 3.70503 3.56013C3.8363 3.43399 4.01435 3.36312 4.2 3.36312H9.8Z"
                                    fill="#72B642"
                                  />
                                </svg>
                              </span>
                            </div>
                            <!-- <span class="date" *ngIf="alertData.alertType == 'on_date'"> -->
                            <!-- {{ formatDate(alertData.triggerDate) }} ({{ getSystemTimezone() }}) -->
                            <!-- </span> -->
                            <!-- <span class="date" *ngIf="alertData.alertType == 'stock_price'"> -->
                            <!-- {{ formatDate(alertData.triggerDate) }} ({{ getSystemTimezone() }}) -->
                            <!-- </span> -->
                            <span class="date">
                              {{ formatDate(alertData.triggerDate) }} ({{ getSystemTimezone() }})
                            </span>
                          </div>
                        </div>

                        <!-- <div class="alert-block alert-class"> -->
                        <!-- <div class="text-alerts"> -->
                        <!-- <div class="text-stock">Date/Time Alert</div> -->
                        <!-- <div class="price-stock">Alert Triggered</div> -->
                        <!-- </div> -->
                        <!-- <div class="symbol-stock"> -->
                        <!-- <div class="symbol-img-name"> -->
                        <!-- <img -->
                        <!-- aria-placeholder="stockimage" -->
                        <!-- class="size-css" -->
                        <!-- src="https://api.twelvedata.com/logo/aa.com" -->
                        <!-- /><span>AAL</span> -->
                        <!-- <span> -->
                        <!-- <svg -->
                        <!-- width="14" -->
                        <!-- height="13" -->
                        <!-- viewBox="0 0 14 13" -->
                        <!-- fill="none" -->
                        <!-- xmlns="http://www.w3.org/2000/svg" -->
                        <!-- > -->
                        <!-- <path -->
                        <!-- d="M11.9 0C12.457 0 12.9911 0.212597 13.3849 0.591021C13.7788 0.969446 14 1.4827 14 2.01787V8.74411C14 9.27929 13.7788 9.79254 13.3849 10.171C12.9911 10.5494 12.457 10.762 11.9 10.762H3.7331L1.4 12.4435C0.8232 12.8592 0 12.4637 0 11.7709V2.01787C0 1.4827 0.221249 0.969446 0.615076 0.591021C1.0089 0.212597 1.54305 0 2.1 0H11.9ZM11.9 1.34525H2.1C1.91435 1.34525 1.7363 1.41611 1.60503 1.54226C1.47375 1.6684 1.4 1.83948 1.4 2.01787V10.762L2.8931 9.68579C3.13543 9.51115 3.43018 9.41674 3.7331 9.41674H11.9C12.0857 9.41674 12.2637 9.34587 12.395 9.21973C12.5263 9.09359 12.6 8.92251 12.6 8.74411V2.01787C12.6 1.83948 12.5263 1.6684 12.395 1.54226C12.2637 1.41611 12.0857 1.34525 11.9 1.34525ZM6.3 6.05362C6.47842 6.05381 6.65002 6.11945 6.77976 6.23714C6.90949 6.35483 6.98756 6.51568 6.99802 6.68682C7.00848 6.85797 6.95053 7.02649 6.83601 7.15795C6.7215 7.28942 6.55907 7.37391 6.3819 7.39416L6.3 7.39887H4.2C4.02158 7.39868 3.84998 7.33303 3.72024 7.21534C3.59051 7.09765 3.51244 6.93681 3.50198 6.76566C3.49152 6.59452 3.54947 6.426 3.66399 6.29453C3.7785 6.16306 3.94093 6.07857 4.1181 6.05833L4.2 6.05362H6.3ZM9.8 3.36312C9.98565 3.36312 10.1637 3.43399 10.295 3.56013C10.4263 3.68627 10.5 3.85735 10.5 4.03575C10.5 4.21414 10.4263 4.38522 10.295 4.51136C10.1637 4.6375 9.98565 4.70837 9.8 4.70837H4.2C4.01435 4.70837 3.8363 4.6375 3.70503 4.51136C3.57375 4.38522 3.5 4.21414 3.5 4.03575C3.5 3.85735 3.57375 3.68627 3.70503 3.56013C3.8363 3.43399 4.01435 3.36312 4.2 3.36312H9.8Z" -->
                        <!-- fill="#72B642" -->
                        <!-- /> -->
                        <!-- </svg> -->
                        <!-- </span> -->
                        <!-- </div> -->
                        <!-- <span class="date">May 25, 12:00 PM</span> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- <div class="alert-block"> -->
                        <!-- <div class="text-alerts"> -->
                        <!-- <div class="text-stock">Date/Time Alert</div> -->
                        <!-- <div class="price-stock">Alert was set on 11 May 2024...</div> -->
                        <!-- </div> -->
                        <!-- <div class="symbol-stock"> -->
                        <!-- <div class="symbol-img-name"> -->
                        <!-- <img -->
                        <!-- aria-placeholder="stockimage" -->
                        <!-- class="size-css" -->
                        <!-- src="https://api.twelvedata.com/logo/asiabroadbandinc.com" -->
                        <!-- /><span>AABB</span> -->
                        <!-- <span> -->
                        <!-- <svg -->
                        <!-- width="14" -->
                        <!-- height="13" -->
                        <!-- viewBox="0 0 14 13" -->
                        <!-- fill="none" -->
                        <!-- xmlns="http://www.w3.org/2000/svg" -->
                        <!-- > -->
                        <!-- <path -->
                        <!-- d="M11.9 0C12.457 0 12.9911 0.212597 13.3849 0.591021C13.7788 0.969446 14 1.4827 14 2.01787V8.74411C14 9.27929 13.7788 9.79254 13.3849 10.171C12.9911 10.5494 12.457 10.762 11.9 10.762H3.7331L1.4 12.4435C0.8232 12.8592 0 12.4637 0 11.7709V2.01787C0 1.4827 0.221249 0.969446 0.615076 0.591021C1.0089 0.212597 1.54305 0 2.1 0H11.9ZM11.9 1.34525H2.1C1.91435 1.34525 1.7363 1.41611 1.60503 1.54226C1.47375 1.6684 1.4 1.83948 1.4 2.01787V10.762L2.8931 9.68579C3.13543 9.51115 3.43018 9.41674 3.7331 9.41674H11.9C12.0857 9.41674 12.2637 9.34587 12.395 9.21973C12.5263 9.09359 12.6 8.92251 12.6 8.74411V2.01787C12.6 1.83948 12.5263 1.6684 12.395 1.54226C12.2637 1.41611 12.0857 1.34525 11.9 1.34525ZM6.3 6.05362C6.47842 6.05381 6.65002 6.11945 6.77976 6.23714C6.90949 6.35483 6.98756 6.51568 6.99802 6.68682C7.00848 6.85797 6.95053 7.02649 6.83601 7.15795C6.7215 7.28942 6.55907 7.37391 6.3819 7.39416L6.3 7.39887H4.2C4.02158 7.39868 3.84998 7.33303 3.72024 7.21534C3.59051 7.09765 3.51244 6.93681 3.50198 6.76566C3.49152 6.59452 3.54947 6.426 3.66399 6.29453C3.7785 6.16306 3.94093 6.07857 4.1181 6.05833L4.2 6.05362H6.3ZM9.8 3.36312C9.98565 3.36312 10.1637 3.43399 10.295 3.56013C10.4263 3.68627 10.5 3.85735 10.5 4.03575C10.5 4.21414 10.4263 4.38522 10.295 4.51136C10.1637 4.6375 9.98565 4.70837 9.8 4.70837H4.2C4.01435 4.70837 3.8363 4.6375 3.70503 4.51136C3.57375 4.38522 3.5 4.21414 3.5 4.03575C3.5 3.85735 3.57375 3.68627 3.70503 3.56013C3.8363 3.43399 4.01435 3.36312 4.2 3.36312H9.8Z" -->
                        <!-- fill="#72B642" -->
                        <!-- /> -->
                        <!-- </svg> -->
                        <!-- </span> -->
                        <!-- </div> -->
                        <!-- <span class="date">May 25, 12:00 PM</span> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- <div class="alert-block"> -->
                        <!-- <div class="text-alerts"> -->
                        <!-- <div class="text-stock">Stock Price Alert</div> -->
                        <!-- <div class="price-stock">Price crossed <span>$40.</span></div> -->
                        <!-- </div> -->
                        <!-- <div class="symbol-stock"> -->
                        <!-- <div class="symbol-img-name"> -->
                        <!-- <img -->
                        <!-- aria-placeholder="stockimage" -->
                        <!-- class="size-css" -->
                        <!-- src="https://api.twelvedata.com/logo/aa.com" -->
                        <!-- /><span>AAL</span> -->
                        <!-- <span> -->
                        <!-- <svg -->
                        <!-- width="14" -->
                        <!-- height="13" -->
                        <!-- viewBox="0 0 14 13" -->
                        <!-- fill="none" -->
                        <!-- xmlns="http://www.w3.org/2000/svg" -->
                        <!-- > -->
                        <!-- <path -->
                        <!-- d="M11.9 0C12.457 0 12.9911 0.212597 13.3849 0.591021C13.7788 0.969446 14 1.4827 14 2.01787V8.74411C14 9.27929 13.7788 9.79254 13.3849 10.171C12.9911 10.5494 12.457 10.762 11.9 10.762H3.7331L1.4 12.4435C0.8232 12.8592 0 12.4637 0 11.7709V2.01787C0 1.4827 0.221249 0.969446 0.615076 0.591021C1.0089 0.212597 1.54305 0 2.1 0H11.9ZM11.9 1.34525H2.1C1.91435 1.34525 1.7363 1.41611 1.60503 1.54226C1.47375 1.6684 1.4 1.83948 1.4 2.01787V10.762L2.8931 9.68579C3.13543 9.51115 3.43018 9.41674 3.7331 9.41674H11.9C12.0857 9.41674 12.2637 9.34587 12.395 9.21973C12.5263 9.09359 12.6 8.92251 12.6 8.74411V2.01787C12.6 1.83948 12.5263 1.6684 12.395 1.54226C12.2637 1.41611 12.0857 1.34525 11.9 1.34525ZM6.3 6.05362C6.47842 6.05381 6.65002 6.11945 6.77976 6.23714C6.90949 6.35483 6.98756 6.51568 6.99802 6.68682C7.00848 6.85797 6.95053 7.02649 6.83601 7.15795C6.7215 7.28942 6.55907 7.37391 6.3819 7.39416L6.3 7.39887H4.2C4.02158 7.39868 3.84998 7.33303 3.72024 7.21534C3.59051 7.09765 3.51244 6.93681 3.50198 6.76566C3.49152 6.59452 3.54947 6.426 3.66399 6.29453C3.7785 6.16306 3.94093 6.07857 4.1181 6.05833L4.2 6.05362H6.3ZM9.8 3.36312C9.98565 3.36312 10.1637 3.43399 10.295 3.56013C10.4263 3.68627 10.5 3.85735 10.5 4.03575C10.5 4.21414 10.4263 4.38522 10.295 4.51136C10.1637 4.6375 9.98565 4.70837 9.8 4.70837H4.2C4.01435 4.70837 3.8363 4.6375 3.70503 4.51136C3.57375 4.38522 3.5 4.21414 3.5 4.03575C3.5 3.85735 3.57375 3.68627 3.70503 3.56013C3.8363 3.43399 4.01435 3.36312 4.2 3.36312H9.8Z" -->
                        <!-- fill="#72B642" -->
                        <!-- /> -->
                        <!-- </svg> -->
                        <!-- </span> -->
                        <!-- </div> -->
                        <!-- <span class="date">May 25, 12:00 PM</span> -->
                        <!-- </div> -->
                        <!-- </div> -->
                      </div>
                    </div>
                    <div class="product-positions-container">
                      <div class="product-view-positions">
                        <ion-button
                          size="small"
                          fill="clear"
                          color="primary"
                          (click)="onViewAlert()"
                          >View Alert History</ion-button
                        >
                      </div>
                    </div>
                    <!-- <div class="Under-construction" style="display: none;"> -->
                    <!-- <div class="inactive-custom"> -->
                    <!-- <ion-icon name="construct-outline"></ion-icon> -->
                    <!-- <h1>Under construction</h1> -->
                    <!-- </div> -->
                    <!-- </div> -->
                  </div>
                  <!-- <div>
                    <div class="ion-no-padding">
                      <div class="Under-construction">
                        <div class="inactive-custom">
                          <ion-icon name="construct-outline"></ion-icon>
                          <h1>Under construction</h1>
                        </div>
                      </div>
                      <ngx-datatable
                        #externalSpendTable
                        class="material body-cell-stacked"
                        [columnMode]="'force'"
                        [rowHeight]="'auto'"
                        [headerHeight]="50"
                        [footerHeight]="40"
                        [externalPaging]="true"
                        [scrollbarH]="true"
                      >
                        <ngx-datatable-column [sortable]="false">
                          <ng-template ngx-datatable-header-template>Recent Alerts</ng-template>
                          <ng-template
                            let-row="row"
                            let-title="row.title"
                            let-link="row.link"
                            ngx-datatable-cell-template
                          >
                            <span (click)="link && openLink(link)"> Stock Price Alert </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [sortable]="false">
                          <ng-template
                            let-row="row"
                            let-source="row.source"
                            let-pubDate="row.pubDate"
                            ngx-datatable-cell-template
                          >
                            <span class="recent-news-name">Price crosses $40. </span>
                            <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="tracker-details-col">
          <div class="card-container treasure-details treasure-details-upcoming no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-full">
                    <div class="climate-name">Upcoming Earnings</div>
                    <div>
                      <div class="loading-spinner UpcomingEaring" *ngIf="isLoadingEaring"></div>
                      <div class="ion-no-padding">
                        <ngx-datatable
                          *ngIf="!isLoadingEaring"
                          class="external-analytics material"
                          [rows]="EarningsStockDetails"
                          [rowHeight]="'45'"
                          [headerHeight]="50"
                          [footerHeight]="0"
                          [limit]="10"
                          [scrollbarH]="'true'"
                        >
                          <ngx-datatable-column
                            [width]="200"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>Ticker</ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-symbol="row.symbol"
                              let-company="row.company"
                            >
                              <div class="symbol-img-name">
                                <span class="large-text">{{ symbol }}</span>
                                <span class="sub-company">{{ company }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [width]="30"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>Price ($)</ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-regularMarketPrice="row.regularMarketPrice"
                            >
                              {{ regularMarketPrice }}
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [cellClass]="getHeaderClassChangePercent"
                            [width]="70"
                            [resizeable]="false"
                            [sortable]="false"
                          >
                            <ng-template ngx-datatable-header-template>Change</ng-template>
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <span [ngClass]="getHeaderClassTodayChange({ row: row })">
                                {{ row.regularMarketChange | number : '1.2-2' }}</span
                              >
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column
                            [width]="70"
                            [sortable]="false"
                            [resizeable]="false"
                          >
                            <ng-template ngx-datatable-header-template>Volume</ng-template>
                            <ng-template
                              ngx-datatable-cell-template
                              let-regularMarketVolume="row.regularMarketVolume"
                            >
                              {{ regularMarketVolume }}
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                      </div>
                      <div class="product-positions-container">
                        <div class="product-view-positions">
                          <ion-button
                            size="small"
                            fill="clear"
                            color="primary"
                            (click)="onUpcomingEarningsView()"
                            >View ALL STOCKS</ion-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="tracker-details-row team-section">
        <ion-col class="tracker-details-col insights-details">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container h-full">
                    <div class="climate-name">Team Insights</div>
                    <div class="Under-construction">
                      <div class="inactive-custom">
                        <ion-icon name="construct-outline"></ion-icon>
                        <h1>Under construction</h1>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <div class="ion-no-padding">
                      <h3>Team Insights</h3>
                           [rowClass]="isRowClass"
                      <ngx-datatable
                        class="external-analytics material"
                        [columnMode]="'force'"
                        [rowHeight]="'45'"
                        [headerHeight]="65"
                        [footerHeight]="40"
                        [scrollbarH]="'true'"
                      >
                        <ngx-datatable-column
                          [width]="100"
                          [width]="200"
                          [sortable]="false"
                          [isTreeColumn]="true"
                          [resizeable]="false"
                        >
                          <ng-template ngx-datatable-header-template>Name</ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column [width]="80" [sortable]="false" [resizeable]="false">
                          <ng-template ngx-datatable-header-template
                            ><span class="color"> Earned(%)</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="80" [sortable]="false" [resizeable]="false">
                          <ng-template ngx-datatable-header-template>
                            <span class="color1"> Lost (%)</span></ng-template
                          >
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </ion-col>
        <ion-col class="tracker-details-col insights-details">
          <div class="card-container treasure-details no-padding">
            <div class="custom-card custom-card-block">
              <div class="card-content">
                <div class="product-wrapper">
                  <div class="product-description-container">
                    <div class="climate-name">Recent News</div>
                    <div>
                      <div class="loading-spinner news-spinner" *ngIf="isLoadingnews"></div>
                      <div class="ion-no-padding">
                        <ngx-datatable
                          #externalSpendTable
                          class="material body-cell-stacked"
                          [rows]="selectedNews"
                          [columnMode]="'force'"
                          [rowHeight]="'auto'"
                          [headerHeight]="0"
                          [footerHeight]="0"
                          [limit]="4"
                          [externalPaging]="true"
                          [scrollbarH]="true"
                          [count]="page.totalElements"
                          [offset]="page.offset"
                        >
                          <ngx-datatable-column [sortable]="false">
                            <ng-template
                              let-row="row"
                              let-title="row.title"
                              let-link="row.link"
                              ngx-datatable-cell-template
                            >
                              <span (click)="link && openLink(link)">
                                {{ title }}
                              </span>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [sortable]="false">
                            <ng-template
                              let-row="row"
                              let-source="row.source"
                              let-pubDate="row.pubDate"
                              ngx-datatable-cell-template
                            >
                              <span class="recent-news-name">{{ source }} </span>
                              <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                      </div>
                      <div class="product-positions-container">
                        <div class="product-view-positions">
                          <ion-button
                            size="small"
                            fill="clear"
                            color="primary"
                            (click)="onUpcomingEarningsView()"
                            >View ALL NEWS</ion-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>

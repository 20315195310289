import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveAlertsComponent } from './active-alerts.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { ActiveAlertRoutingModule } from './active-alert-routing.module';

@NgModule({
  declarations: [ActiveAlertsComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    IonicModule,
    ActiveAlertRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  // exports: [ActiveAlertsComponent],
  entryComponents: [ActiveAlertsComponent],
})
export class ActiveAlertModule {}

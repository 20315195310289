import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-active-alerts',
  templateUrl: './active-alerts.component.html',
  styleUrls: ['./active-alerts.component.scss'],
})
export class ActiveAlertsComponent implements OnInit {
  role: any;
  activeToolSlug: any;
  alertData: any = [];
  constructor(
    public storageServ: StorageService,
    private router: Router,
    private toolService: ToolServiceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.role = this.storageServ.get('role');
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
  }

  ngOnInit() {
    this.getAllalert();
  }

  onAlertClick() {
    this.router.navigate([`/${this.role}/settings`], {
      queryParams: { tab: 'settings' },
    });
  }
  getAllalert() {
    this.toolService.getAllalert().subscribe((res: any) => {
      this.alertData = res.data && res.data.length ? res.data : [];
    });
  }
  onClickAlert(alert: any, alertType: any) {
    this.toolService.setSegmentValue('alert');
    this.toolService.setAlertValue(alertType);
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${alert.symbol}`,
    ]);
  }
}

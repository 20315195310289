import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  ViewChild,
  ɵɵtrustConstantResourceUrl,
  Renderer2,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import moment from 'moment';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-add-potion-modal',
  templateUrl: './add-potion-modal.component.html',
  styleUrls: ['./add-potion-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPotionModalComponent implements OnInit {
  @ViewChild('dateInput') dateInput: any; // Reference to the input element
  selectedDateTime: string;
  showDatePicker: boolean;
  isaddLegTrue: Boolean = false;
  showDatePickerExpiration: boolean;
  isSplitStrike: boolean = false;
  // toggleChecked: boolean = false;
  isaddNote: boolean = false;
  symbol: string;
  stockPrice: string;
  slug: string;
  formType: string;
  mode;
  positionList: any;
  isDateSelected = false;
  editData: any;
  @Input() componentData: any;
  addOption: FormGroup;
  formTitle: string;
  listOfExpiration: any[] = [];
  currentRoute: any;
  selectedExpirationDate: number;
  positionDetail = [];
  stockDetails: any;
  statusChange = 'open';
  isStatusChecked: boolean;
  isAssigned: boolean;
  highlightSelectedDateTime: boolean;
  formattedDate: string;
  todayDate: any;
  today: any;
  noteDes: any;
  notesSlug: any;
  strike: any;
  isNew: boolean;
  positionStatus: any;
  constructor(
    private formBuilder: FormBuilder,
    private toolsService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.addOption = this.formBuilder.group({
      purchasedDate: [this.formattedDate, Validators.required],
      expirationDate: ['', Validators.required],
      strikeBought: [''],
      underlyingPrice: [''],
      contracts: [''],
      positionStatus: [''],
      premiumPaid: [''],
      exitPremium: [''],
      credit: [''],
      isHistoricalLeg: [false],
      legs: this.formBuilder.array([]),
      isAssigned: [false],
      isSplitStrike: [false],
      noteDes: [''],
      notesCheck: [false],
    });
    this.addDefaultLegs();
  }

  // Getter for FormArray
  get legs(): FormArray {
    return this.addOption.get('legs') as FormArray;
  }

  // Add a single leg (used for default entries or when "Add Leg" is clicked)
  addLeg(): void {
    const legGroup = this.formBuilder.group({
      strikeSold: [''],
      contracts: [''],
      credit: [''],
      exitPremium: [''],
    });
    this.legs.push(legGroup);
    this.isaddLegTrue = !this.isaddLegTrue;
  }

  // Add default legs (Leg 1 and Leg 2)
  addDefaultLegs(): void {
    this.addLeg(); // Leg 1
    this.addLeg(); // Leg 2
  }

  // Optional: Remove a leg if needed
  removeLeg(index: number): void {
    if (this.legs.length > 1) {
      this.legs.removeAt(index);
    } else {
      this.toastServ.presentToast('The first leg cannot be removed.', 'warning');
    }
  }

  ngOnInit() {
    this.noteDes = '';
    const todayDate = new Date();
    this.today = todayDate.toISOString().split('T')[0];
    this.isSplitStrike = false;
    this.isStatusChecked = false;
    this.getNotes(this.componentData.parentSlug);
    if (this.componentData && this.componentData.componentType === 'positionComponent') {
      this.setInitData();
    }
    this.menuService.getOpenMenuSubject().subscribe((res: any) => {
      this.componentData = {};
      if (res && res.componentType === 'positionComponent') {
        this.componentData = res;
        this.getNotes(this.componentData.parentSlug);
        this.setInitData();
      }
    });
    // this.cdr.detectChanges();
  }

  refreshToggleState(newState: boolean): void {
    const toggle = this.el.nativeElement.querySelector('#splitToggle');
    if (toggle) {
      this.renderer.setProperty(toggle, 'checked', newState);
    }
  }

  setInitData() {
    this.addOption.reset();
    this.isSplitStrike = false;
    this.isStatusChecked = false;
    this.addOption.controls.isHistoricalLeg.setValue(false);
    this.isaddNote = false;
    if (this.componentData) {
      this.formType = this.componentData.formType;
      this.mode = this.componentData.mode;
      this.symbol = this.componentData.symbol;
      this.stockPrice = this.componentData.stockPrice;
      this.todayDate = this.componentData.todayDate;
      this.slug = this.componentData.slug;
      this.positionList = this.componentData.positionList;
      this.positionStatus = this.componentData.positionStatus;
      this.editData = this.componentData.editData;

      let editedData: any;
      let type = typeof this.componentData.editData;
      if (Array.isArray(this.componentData.editData)) {
        editedData = this.editData[0];
      } else if (type === 'object') {
        editedData = this.editData;
      }
      if (this.positionStatus == 'closed') {
        this.statusChange = 'closed';
        this.isStatusChecked = true;
      } else {
        this.isStatusChecked = editedData?.positionStatus === 'closed' ? true : false;
        this.statusChange = editedData?.positionStatus;
      }

      if (this.mode === 'edit') {
        this.showDatePicker = false;
        // this.toggleChecked = false;

        // Populate form with data from editData
        // this.isaddNote = this.noteDes !== null ? true : false;

        this.addOption.patchValue({
          purchasedDate: editedData.createdAt,
          expirationDate: editedData.expirationPeriod,
          underlyingPrice: editedData.underlyingStock,
          positionStatus: editedData.positionStatus,
          premiumPaid: this.formType === 'weekly' ? editedData.premiumSold : editedData.premiumPaid,
          credit: this.formType === 'weekly' ? editedData.premiumSold : editedData.premiumPaid,
          exitPremium: editedData.exitPremium || '',
          isHistoricalLeg: editedData?.isHistoricalLeg,
          isAssigned: this.editData.isAssigned === true ? true : false,
          isSplitStrike: editedData.isSplit,
          noteDes: this.noteDes,
          notesCheck: this.isaddNote,
        });

        this.isSplitStrike = editedData.isSplit;
        const legsFormArray = this.addOption.get('legs') as FormArray;
        legsFormArray.clear(); // Clear any existing controls
        if (this.formType === 'weekly') {
          if (this.editData && Array.isArray(this.editData)) {
            if (this.editData.length > 0) {
              // Populate dynamic legs (strikeSold, contracts, and credit)

              this.editData.forEach((leg: any) => {
                legsFormArray.push(
                  this.formBuilder.group({
                    strikeSold: [leg.strikeSold.toString(), Validators.required],
                    contracts: [leg.numberOfContracts, Validators.required],
                    credit: [leg.premiumSold, Validators.required],
                    exitPremium: [leg.exitPremium, Validators.required],
                  })
                );
              });
            }
          }
        }
        if (this.formType === 'long') {
          if (this.editData && Array.isArray(this.editData)) {
            if (this.editData.length > 0) {
              // Populate dynamic legs (strikeSold, contracts, and credit)
              // const legsFormArray = this.addOption.get('legs') as FormArray;
              // legsFormArray.clear(); // Clear any existing controls
              this.editData.forEach((leg: any) => {
                legsFormArray.push(
                  this.formBuilder.group({
                    strikeSold: [leg.strikeBought.toString(), Validators.required],
                    contracts: [leg.numberOfContracts, Validators.required],
                    credit: [leg.premiumPaid, Validators.required],
                    exitPremium: [leg.exitPremium, Validators.required],
                  })
                );
              });
            }
          }
        }
      }
    }

    this.formTitle = this.formType === 'long' ? 'Long Put Protection' : 'Weekly Sold Put';

    // Set default values for "add" mode
    if (this.mode === 'add') {
      this.addOption.patchValue({
        underlyingPrice: this.stockPrice,
        purchasedDate: this.todayDate,
        isAssigned: false,
        isHistoricalLeg: this.positionStatus != 'closed' ? false : true,
      });
    }

    // Fetch necessary data for the form
    this.getListStrike(this.symbol);
  }

  onaddNoteSelect(event: any) {
    this.isaddNote = event.detail.checked ? true : false;
    this.addOption.controls.notesCheck.setValue(this.isaddNote);
  }
  cancel(isNotify = 'true') {
    this.showDatePicker = false;
    this.isStatusChecked = false;
    this.menuController.close();
    this.addOption.reset();
    if (isNotify === 'true') {
      // this.menuService.setCloseMenuSubject({});
    }
  }

  toggleStatus(event: any) {
    if (this.positionStatus != 'closed') {
      this.statusChange = event.target.checked ? 'closed' : 'open';
      this.isStatusChecked = event.target.checked;
    }
  }

  getListStrike(symbol: string, event?, type?: string) {
    const editedData = Array.isArray(this.editData) ? this.editData[0] : this.editData;
    if (!this.isDateSelected) {
      let selectedExpirationDate = null;
      if (type && type === 'fromExpirationDate' && !event?.target?.value) {
        return;
      }
      if (event && event.target && event.target.value) {
        selectedExpirationDate = moment.utc(event.target.value, 'MM/DD/YYYY').unix();
        this.isDateSelected = true;
      }
      // if (this.mode === 'edit' && !event?.target?.value) {
      //   const formattedDate = moment.utc(editedData.expirationPeriod).format('MM/DD/YYYY');
      //   selectedExpirationDate = moment.utc(formattedDate).unix();
      // }
      this.showDatePicker = false;
      if (this.addOption.value.positionStatus !== 'overdue') {
        this.toolsService
          .getStrike(symbol, selectedExpirationDate ? selectedExpirationDate : '')
          .subscribe((res: any) => {
            const body = res.data.result[0];
            if (body) {
              if (selectedExpirationDate) {
                const { options } = body;
                if (options && options.length && options[0].puts && options[0].puts.length) {
                  this.isDateSelected = false;

                  this.positionDetail = options[0].puts;
                  this.stockDetails = body.quote;
                }
                if (this.mode === 'edit' && !event?.target?.value) {
                  if (body?.expirationDates) {
                    this.isDateSelected = false;
                    this.listOfExpiration = body?.expirationDates;
                    this.listOfExpiration = this.listOfExpiration?.map((expiration) =>
                      moment.utc(new Date(expiration * 1000)).format('MM/DD/YYYY')
                    );
                  }
                }
              } else {
                this.listOfExpiration = body?.expirationDates;
                if (this.listOfExpiration.length) {
                  this.isDateSelected = false;
                  this.listOfExpiration = this.listOfExpiration?.map((expiration) =>
                    moment.utc(new Date(expiration * 1000)).format('MM/DD/YYYY')
                  );
                }

                this.stockDetails = body.quote;
              }
            }
          });
      }

      if (this.mode === 'edit') {
        if (editedData) {
          this.isSplitStrike = editedData?.isSplit ? editedData.isSplit : false;
          const formattedPurchasedDate = moment.utc(editedData.createdAt).format('MM/DD/YYYY');
          const formattedExpirationDate = moment
            .utc(editedData.expirationPeriod)
            .format('MM/DD/YYYY');
          const formattedExpirationDateBefore = moment
            .utc(editedData.expirationPeriod)
            .format('MM/DD/YYYY');
          {
            if (this.componentData.isPositionExpired === true) {
              // this.addOption.get('purchasedDate').disable();
              // this.addOption.get('expirationDate').disable();
              // this.addOption.get('strikeBought').disable();
              // this.addOption.get('underlyingPrice').disable();
              // this.addOption.get('contracts').disable();
              // this.addOption.get('premiumPaid').disable();
              // this.addOption.get('credit').disable();
              this.addOption.patchValue({
                expirationDate: formattedExpirationDateBefore,
              });
            } else {
              this.addOption.get('purchasedDate').enable();
              this.addOption.get('expirationDate').enable();
              this.addOption.get('strikeBought').enable();
              this.addOption.get('underlyingPrice').enable();
              this.addOption.get('contracts').enable();
              this.addOption.get('premiumPaid').enable();
              this.addOption.get('credit').enable();
              this.addOption.patchValue({
                expirationDate: formattedExpirationDate,
              });
            }
            this.addOption.patchValue({
              purchasedDate: formattedPurchasedDate,
              strikeBought:
                this.formType === 'long'
                  ? editedData?.strikeBought.toString()
                  : editedData?.strikeSold.toString(),
              underlyingPrice: editedData.underlyingStock,
              contracts: editedData.numberOfContracts,
              premiumPaid: editedData.premiumPaid,
              exitPremium: editedData.exitPremium,
              credit: editedData.premiumSold,
              positionStatus: editedData.positionStatus,
            });
          }
        }
      }
    }
  }

  getPremiumPaid(event) {
    const selectedStrike = parseFloat(event?.detail.value);
    const selectedPosition = this.positionDetail.find(
      (position) => position.strike.raw === selectedStrike
    );
    if (selectedPosition) {
      const lastPrice = selectedPosition.lastPrice?.raw;

      if (this.formType === 'long') {
        const bid = selectedPosition.bid?.raw;
        this.addOption.get('exitPremium').setValue(bid);
      }
      if (this.formType === 'weekly') {
        const ask = selectedPosition.ask?.raw;
        this.addOption.get('exitPremium').setValue(ask);
      }
    }
  }
  getExitPremium(strikePrice?) {
    let exitPremium = 0;
    const selectedPosition = this.positionDetail.find(
      (position) => position.strike?.raw == strikePrice
    );
    if (this.formType === 'long') {
      exitPremium = selectedPosition?.bid?.raw;
    }
    if (this.formType === 'weekly') {
      exitPremium = selectedPosition?.ask?.raw;
    }
    return exitPremium;
  }

  createLegs() {
    let putObject: any;
    let tradeProfitLoss = 0;
    let profitLossPercentage = 0;
    const lastPosition =
      this.positionList && this.positionList.length > 0
        ? this.positionList[this.positionList.length - 1]
        : null;

    const purchasedDate = this.addOption.get('purchasedDate').value;
    const formattedPurchasedDate = moment(purchasedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const expirationDate = this.addOption.get('expirationDate').value;
    const formattedExpireDate = moment(expirationDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const strikeBought = this.addOption.get('strikeBought').value;
    const exitPremiumValue = this.addOption.get('exitPremium').value;
    const contracts = [this.addOption.get('contracts').value];
    const premiumPaid = [this.addOption.get('premiumPaid')?.value];
    const underlyingPrice = parseFloat(this.addOption.get('underlyingPrice').value);
    const positionStatus = this.addOption.get('isHistoricalLeg').value
      ? 'closed'
      : this.isStatusChecked
      ? 'closed'
      : 'open';
    const isAssigned = this.addOption.get('isAssigned').value;
    const isHistoricalLeg = this.addOption.get('isHistoricalLeg').value ? true : false;
    const isSplit = this.addOption.get('isSplitStrike').value ? true : false;
    this.noteDes = this.addOption.get('noteDes').value;
    /*  const exitPremium =
    positionStatus === 'closed'
      ? Number(this.addOption.get('exitPremium').value)
      : this.getExitPremium(strikeBought)
      ? this.getExitPremium(strikeBought)
      : 0; */

    const credit = [this.addOption.get('credit').value];

    // Bind FormArray values
    const legsFormArray = this.addOption.get('legs') as FormArray;
    const exitPremium = !this.isSplitStrike
      ? Array.isArray(exitPremiumValue)
        ? exitPremiumValue.map((value: any) => Number(value))
        : [Number(exitPremiumValue)]
      : legsFormArray.controls.map((control) => Number(control.get('exitPremium').value));
    const strikeSold = !this.isSplitStrike
      ? Array.isArray(strikeBought)
        ? strikeBought.map((value: any) => Number(value))
        : [Number(strikeBought)]
      : legsFormArray.controls.map((control) => Number(control.get('strikeSold').value));
    const premiumSold =
      this.formType === 'long'
        ? !this.isSplitStrike
          ? [this.addOption.get('premiumPaid').value]
          : legsFormArray.controls.map((control) => Number(control.get('credit').value))
        : !this.isSplitStrike
        ? [this.addOption.get('credit').value]
        : legsFormArray.controls.map((control) => Number(control.get('credit').value));
    const numberOfContracts = legsFormArray.controls.map((control) =>
      Number(control.get('contracts').value)
    );
    if (this.formType === 'long') {
      putObject = {
        datePurchased: formattedPurchasedDate,
        premiumPaid: premiumSold,
        syntheticStrike: 0,
        averagePremiumLoss: 0,
        strikeBought: strikeSold,
      };
    }

    if (this.formType === 'weekly') {
      putObject = {
        dateSold: formattedPurchasedDate,
        strikeSold: strikeSold,
        premiumSold: premiumSold,
        premiumAsPercentageOfStock: 0,
        avgPremiumEarned: 0,
      };
    }

    const editedSlug = [];
    let type = typeof this.componentData.editData;
    if (Array.isArray(this.componentData.editData)) {
      this.componentData?.editData?.forEach((element) => {
        editedSlug.push(element.slug);
      });
    } else if (type === 'object') {
      editedSlug.push(this.componentData.editData.slug);
    }
    const payload = {
      tickerSymbol: this.symbol,
      tickerSlug: this.slug,
      positionType: this.formType,
      positionStatus: this.positionStatus != 'closed' ? positionStatus : 'closed',
      optionDirection: '',
      expirationPeriod: formattedExpireDate,
      numberOfContracts: !this.isSplitStrike ? contracts : numberOfContracts,
      tradeProfitLoss: tradeProfitLoss,
      profitLossPercentage: profitLossPercentage,
      exitPremium: exitPremium,
      underlyingStock: underlyingPrice,
      isSplit: isSplit,
      isAssigned: isAssigned,
      isHistoricalLeg: isHistoricalLeg,
      noteDes: this.noteDes,
      slugs: editedSlug,
      ...(this.formType === 'long' ? { longPut: putObject } : {}),
      ...(this.formType === 'weekly' ? { weeklyPut: putObject } : {}),
    };

    let notesPayload: any;
    let strike;
    if (this.formType === 'long') {
      strike = payload.longPut.strikeBought.reduce((total, position) => {
        return total + (position ?? 0);
      }, 0);
    } else if (this.formType === 'weekly') {
      strike = payload.weeklyPut.strikeSold.reduce((total, position) => {
        return total + (position ?? 0);
      }, 0);
    }
    if (this.mode === 'add') {
      this.toolsService.createLeg(payload).subscribe((res: any) => {
        if (res) {
          if (!!this.noteDes) {
            notesPayload = {
              tickerSlug: this.slug,
              description: this.noteDes,
              parentSlug: res?.data[0]?.parentSlug,
              type:
                this.formType === 'long'
                  ? 'long'
                  : this.formType == 'weekly'
                  ? 'weekly'
                  : 'overall',
              expiration: formattedExpireDate,
              strike: strike,
            };
            this.toolsService.createLegNotes(notesPayload).subscribe((res: any) => {});
          }
          this.menuService.setCloseMenuSubject(payload);
          this.addOption.reset();
          this.isStatusChecked = false;
          this.menuController.close('payload');
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
        }
      });
    }
    if (this.mode === 'edit') {
      let editedData: any;
      let type = typeof this.componentData.editData;
      if (Array.isArray(this.componentData.editData)) {
        editedData = this.editData[0];
      } else if (type === 'object') {
        editedData = this.editData;
      }
      this.toolsService.updatePosition(editedData.slug, payload).subscribe((res: any) => {
        if (res) {
          notesPayload = {
            tickerSlug: payload.tickerSlug,
            description: this.noteDes,
            parentSlug: res?.updatedLegs[0]?.parentSlug,
            type:
              this.formType === 'long' ? 'long' : this.formType == 'weekly' ? 'weekly' : 'overall',
            expiration: formattedExpireDate,
            strike: strike,
          };
          if (this.isNew) {
            if (!!this.noteDes) {
              this.toolsService.createLegNotes(notesPayload).subscribe((res: any) => {});
            }
          } else {
            if (this.noteDes.trim() == '') {
              this.toolsService.deleteNote(this.notesSlug).subscribe((response: any) => {});
            } else {
              this.toolsService
                .updateLegNotes(this.notesSlug, notesPayload)
                .subscribe((res: any) => {});
            }
          }
          this.menuService.setCloseMenuSubject(payload);
          this.addOption.reset();
          this.isStatusChecked = false;
          this.menuController.close('payload');
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
        }
      });
    }
  }

  closeDatePicker(event) {
    const value = event.target.value;
    this.addOption.patchValue({
      purchasedDate: this.formatDate(new Date(value)),
    });
    this.showDatePicker = false;
  }

  openDatePicker() {
    if (!this.showDatePicker) {
      this.showDatePicker = true;
      const purchasedDateValue = this.addOption.get('purchasedDate').value;
      this.selectedDateTime = purchasedDateValue ? purchasedDateValue : new Date().toISOString();
      this.cdr.detectChanges();
    } else {
      this.showDatePicker = false;
    }
  }

  openDatePickerExpiration() {
    if (!this.showDatePickerExpiration) {
      this.showDatePickerExpiration = true;
      const expirationDateValue = this.addOption.get('expirationDate').value;
      this.selectedDateTime = expirationDateValue ? expirationDateValue : new Date().toISOString();
      this.cdr.detectChanges();
    } else {
      this.showDatePickerExpiration = false;
    }
  }

  closeDatePickerExpiration(event) {
    const value = event.target.value;
    this.addOption.patchValue({
      expirationDate: this.formatDate(new Date(value)),
    });
    this.showDatePickerExpiration = false;
  }

  formatDate(date: Date): string {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero for month
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero for day
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  onSplitStrikeSelect() {
    this.isSplitStrike = !this.isSplitStrike;
    if (this.isSplitStrike && this.addOption.value.legs.length == 0) {
      this.addDefaultLegs();
    }
  }
  onIsHistorical(event: any) {
    if (this.positionStatus != 'closed') {
      this.addOption.value.isHistoricalLeg = event.detail.checked ? true : false;
      // this.addOption.patchValue({
      //   isHistoricalLeg: this.toggleChecked,
      // });
      if (this.addOption.value.isHistoricalLeg) {
        this.isStatusChecked = true;
      } else {
        this.isStatusChecked = false;
      }
    }
  }
  getNotes(parentSlug) {
    let notes: any;
    this.noteDes = '';
    this.toolsService.getLegNotes(parentSlug).subscribe((res1: any) => {
      if (res1.data.length > 0) {
        let i = res1.data.length - 1;
        notes = res1.data[i];
        this.noteDes = notes?.description;
        this.isaddNote = !!notes?.description ? true : false;
        this.addOption.patchValue({
          noteDes: this.noteDes,
          notesCheck: this.isaddNote,
        });
        this.strike = notes?.strike;
        this.isNew = !notes?.description ? true : false;
        this.notesSlug = notes?.slug;
      } else {
        this.noteDes = '';
        this.notesSlug = '';
        this.isNew = true;
        this.isaddNote = false;
        this.addOption.patchValue({
          noteDes: this.noteDes,
          notesCheck: this.isaddNote,
        });
      }
    });
  }
}

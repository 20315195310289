<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a *ngIf="showForm !== false" (click)="toggleView()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to Notes
      </a>
      <a *ngIf="!showForm" (click)="cancel()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to Profile
      </a>

      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>

<ng-container *ngIf="!showForm">
  <div class="page-heading note-add">
    <h2>Notes</h2>
    <div class="toggle-btn-header">
      <ion-item lines="none" class="input-block-custom ion-no-padding width-css toggle-gray-sm">
        <ion-toggle
          lines="none"
          class="ion-no-padding"
          [checked]="isShowOverAll"
          (ionChange)="changeToggle($event)"
          justify="end"
        ></ion-toggle>
        <div>Stock Notes</div>
      </ion-item>
      <ion-button
        fill="outline"
        class=""
        *ngIf="!showForm !== false && !editSlug"
        (click)="toggleView()"
        >Create Note</ion-button
      >
      <ion-button
        fill="outline"
        class=""
        *ngIf="showForm == false && editSlug"
        (click)="toggleView()"
        >Create Note</ion-button
      >
    </div>
  </div>
  <ng-container>
    <div *ngIf="noteData.length > 0" class="notedata-block">
      <div *ngFor="let list of noteData">
        <div class="list-container" *ngIf="list.type !== 'weekly' && list.type !== 'long'">
          <div class="lsit-data-wrap" *ngIf="list.description">
            <div class="list-data">
              <div class="alert-info">
                {{ list.description }}
              </div>
            </div>
          </div>
          <div class="note-icon-date">
            <div *ngIf="list.createdAt">
              <div class="list-data">
                <div class="note-info" *ngIf="list.createdAt === list.updatedAt">
                  {{ list.createdAt | date : 'MMM d, yyyy, h:mm:ss a' }}
                </div>
                <div class="note-info" *ngIf="list.createdAt !== list.updatedAt">
                  {{ list.updatedAt | date : 'MMM d, yyyy, h:mm:ss a' }}
                </div>
              </div>
            </div>
            <div class="icon-group">
              <div class="edit">
                <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
                  <ion-icon name="create-outline"></ion-icon>
                </button>
              </div>
              <div class="delete">
                <button ion-button icon-only (click)="deleteNotes(list)" class="chat-menu-btn">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="list-container active" *ngIf="list.type == 'weekly' || list.type == 'long'">
          <div class="current-view">
            <div class="top-box-data">
              <span class="green-lb">{{ list.type == 'weekly' ? 'Weekly Put' : 'Long Put' }}</span>
              <div class="strip-line">
                <div class="text-data">
                  Expiration: <span>{{ list.expiration | date : 'MM/dd/yyyy' }}</span>
                </div>
                <div class="bullet-cirecl">
                  <svg
                    width="4"
                    height="3"
                    viewBox="0 0 4 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.07227" cy="1.5" r="1.5" fill="#ACACAC" />
                  </svg>
                </div>
                <div class="text-data bullet">
                  Strike: <span>${{ list.strike }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="lsit-data-wrap">
            <div class="list-data">
              <div class="alert-info">{{ list.description }}</div>
            </div>
          </div>
          <div class="note-icon-date">
            <div>
              <div class="list-data">
                <div class="note-info">{{ list.updatedAt | date : 'MMM d, yyyy, h:mm:ss a' }}</div>
              </div>
            </div>
            <div class="icon-group">
              <div class="edit">
                <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
                  <ion-icon name="create-outline"></ion-icon>
                </button>
              </div>
              <div class="delete">
                <button ion-button icon-only (click)="deleteNotes(list)" class="chat-menu-btn">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowOverAll" class="notedata-block">
      <div *ngIf="stockeNotesData.length > 0">Stock Notes</div>
      <div *ngFor="let list of noteData">
        <div class="list-container" *ngIf="list.type !== 'weekly' && list.type !== 'long'">
          <div class="lsit-data-wrap" *ngIf="list.description">
            <div class="list-data">
              <div class="alert-info">
                {{ list.description }}
              </div>
            </div>
          </div>
          <div class="note-icon-date">
            <div *ngIf="list.createdAt">
              <div class="list-data">
                <div class="note-info" *ngIf="list.createdAt === list.updatedAt">
                  {{ list.createdAt | date : 'MMM d, yyyy, h:mm:ss a' }}
                </div>
                <div class="note-info" *ngIf="list.createdAt !== list.updatedAt">
                  {{ list.updatedAt | date : 'MMM d, yyyy, h:mm:ss a' }}
                </div>
              </div>
            </div>
            <div class="icon-group">
              <div class="edit">
                <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
                  <ion-icon name="create-outline"></ion-icon>
                </button>
              </div>
              <div class="delete">
                <button ion-button icon-only (click)="deleteNotes(list)" class="chat-menu-btn">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="noteData.length === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You don't have any Notes</span>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="showForm">
  <div class="page-heading">
    <h2>{{ editSlug ? 'Edit' : 'Create' }} Notes</h2>
  </div>
  <div class="form-container p-0">
    <div class="form-container p-0">
      <form (ngSubmit)="onNotesCreateSubmit()" [formGroup]="tickerNotesForm">
        <div class="create-note-add input-container" *ngIf="!editSlug">
          <ion-label>
            {{ editSlug ? 'Edit' : 'Create' }} Note for {{ selectedCreatefor }}</ion-label
          >

          <ion-item class="ion-item-no-inner-padding hover-bg price-filter-selection">
            <ion-select
              [(ngModel)]="selectedCreatefor"
              [ngModelOptions]="{ standalone: true }"
              line="none"
              placeholder="Select the one"
              interface="popover"
              class="select-dropdown"
            >
              <ion-select-option *ngFor="let item of CREATNOTEFOR" [value]="item.value"
                >{{ item.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            class="create-note-add input-container"
            *ngIf="selectedCreatefor === 'weeklyput' || selectedCreatefor === 'longputprotection'"
          >
            <ion-label *ngIf="!!selectedCreatefor"
              >Select
              {{ selectedCreatefor == 'weeklyput' ? 'Weekly Leg' : 'Protective Leg' }}</ion-label
            >
            <ion-item
              *ngIf="selectedCreatefor == 'weeklyput'"
              class="ion-item-no-inner-padding hover-bg price-filter-selection"
            >
              <ion-select
                line="none"
                [(ngModel)]="selectedLeg"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select the one"
                okText="Okay"
                cancelText="Dismiss"
                interface="popover"
                (ionChange)="onChangeLeg($event)"
                class="select-dropdown"
              >
                <ion-select-option *ngFor="let leg of weeklyLeg" [value]="leg.value">
                  <span [innerHTML]="leg.name | keepHtml"></span>
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item
              *ngIf="selectedCreatefor == 'longputprotection'"
              class="ion-item-no-inner-padding hover-bg price-filter-selection"
            >
              <ion-select
                line="none"
                [(ngModel)]="selectedLeg"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select the one"
                okText="Okay"
                cancelText="Dismiss"
                interface="popover"
                (ionChange)="onChangeLeg($event)"
                class="select-dropdown"
              >
                <ion-select-option *ngFor="let leg of longLeg" [value]="leg.value"
                  >{{ leg.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>

        <div class="edit-trade-note" *ngIf="editSlug">
          <div
            class="top-box-data"
            *ngIf="selectedLeg?.type === 'weekly' || selectedLeg?.type === 'long'"
          >
            <span class="green-lb">
              {{ selectedLeg?.type == 'weekly' ? 'Weekly Leg' : 'Protective Leg' }}</span
            >
            <div class="strip-line">
              <div class="text-data">
                Expiration: <span>{{ selectedLeg?.expiration | date : 'MM/dd/yyyy' }}</span>
              </div>
              <div class="bullet-cirecl line"></div>
              <div class="text-data bullet">
                Strike:
                <span>${{ selectedLeg?.strike }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label style="font-size: 18px" for="notes">Note Description</label>
          <textarea
            class="note-description"
            formControlName="description"
            id="description"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <ion-button
          type="submit"
          [disabled]="tickerNotesForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ editSlug ? 'Update' : 'Create' }} Notes
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>

<div>
  <ion-header class="ion-no-border header-top-custom">
    <ion-toolbar>
      <div class="close-heading-block">
        <div class="page-heading">
          <div class="filter-ion-icon">
            <ion-icon name="options-outline" class="filter-icon"></ion-icon>
          </div>
          <h2>Filters</h2>
        </div>
        <ion-buttons slot="end">
          <ion-button (click)="cancel()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </ion-header>
</div>

<div *ngIf="!showForm">
  <div class="popular-block">
    <div *ngIf="isAnyFilterApplied()" class="back-to">
      <a (click)="clearFilters()"
        ><ion-icon slot="icon-only" name="close-outline"></ion-icon>Clear All Filter</a
      >
    </div>
    <div class="page-heading">
      <h5>Most Used</h5>
    </div>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('price')">
        <div class="filter-data">
          <span> Price</span>
          <span
            *ngIf="selectedPriceData"
            (click)="clearSpecificFilter('price'); $event.stopPropagation()"
            class="filter-selector"
          >
            {{ selectedPriceData }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon>
          </span>
        </div>

        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('volume')">
        <div class="filter-data">
          <span> Volume </span>
          <span
            *ngIf="selectedVolumeData"
            (click)="clearSpecificFilter('volume'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedVolumeData }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('earnings')">
        <div class="filter-data">
          <span>Earnings</span>
          <span
            *ngIf="selectedEarningsData && selectedEarningsData != 'any'"
            (click)="clearSpecificFilter('earnings'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedEarningsData }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('isWeeklyStock')">
        <div class="filter-data">
          <span> Has Weekly Options ? </span>
          <span
            *ngIf="selectedWeeklyOptionData && selectedWeeklyOptionData != 'any'"
            (click)="clearSpecificFilter('isWeeklyStock'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedWeeklyOptionData }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('marketcap')">
        <div class="filter-data">
          <span> Market Cap </span>
          <span
            *ngIf="selectedMarketCapData && selectedMarketCapData != 'any'"
            (click)="clearSpecificFilter('marketcap'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedMarketCapData }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>

    <div class="page-heading-Data">
      <h5>Price Change</h5>
    </div>

    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change')">
        <div class="filter-data">
          <span> Price Change {{ !!selectedPriceChange ? selectedPriceChange : '1D' }}</span>
          <span
            *ngIf="selectedChangeData1Day"
            (click)="clearSpecificFilter('change'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData1Day }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>

    <!-- <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change1Day')">
        <div class="filter-data">
          <span> Price Change 1D </span>
          <span
            *ngIf="selectedChangeData1Day"
            (click)="clearSpecificFilter('change1Day'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData1Day }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change7Days')">
        <div class="filter-data">
          <span> Price Change 1W </span>
          <span
            *ngIf="selectedChangeDataLast7Days && selectedChangeDataLast7Days != 'any'"
            (click)="clearSpecificFilter('priceChangeLast7Days'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeDataLast7Days }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change30Days')">
        <div class="filter-data">
          <span> Price Change 1M</span>
          <span
            *ngIf="selectedChangeData30Days && selectedChangeData30Days != 'any'"
            (click)="clearSpecificFilter('priceChange30Days'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData30Days }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change60Days')">
        <div class="filter-data">
          <span> Price Change 3M</span>
          <span
            *ngIf="selectedChangeData60Days && selectedChangeData60Days != 'any'"
            (click)="clearSpecificFilter('priceChange60Days'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData60Days }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change90Days')">
        <div class="filter-data">
          <span> Price Change 6M </span>
          <span
            *ngIf="selectedChangeData90Days && selectedChangeData90Days != 'any'"
            (click)="clearSpecificFilter('priceChange90Days'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData90Days }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change1YTD')">
        <div class="filter-data">
          <span> Price Change YTD</span>
          <span
            *ngIf="selectedChangeData1YTD && selectedChangeData1YTD != 'any'"
            (click)="clearSpecificFilter('priceChange1YTD'); $event.stopPropagation()"
            class="filter-selector"
            >{{ selectedChangeData1YTD }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul>
    <ul class="content-custom list-content-block">
      <li class="background-change" (click)="getFilterWiseShow('change365Days')">
        <div class="filter-data">
          <span> Price Change 1Y </span>
          <span
            *ngIf="selectedChangeData365Days && selectedChangeData365Days != 'any'"
            (click)="clearSpecificFilter('priceChange365Days'); $event.stopPropagation()"
            class="filter-selector"
          >
            {{ selectedChangeData365Days }}
            <ion-icon class="filter-selctor-cancel" name="close-outline"></ion-icon
          ></span>
        </div>
        <ion-icon class="icon-color" name="chevron-forward-outline"></ion-icon>
      </li>
    </ul> -->
  </div>
  <div class="sticky-button">
    <ion-button
      type="submit"
      color="primary"
      fill="solid"
      style="width: 100%"
      (click)="applyFilterList()"
    >
      <ng-container *ngIf="isLoading; else buttonText"> Apply</ng-container>
      <ng-template #buttonText
        ><ion-spinner slot="start" name="crescent"></ion-spinner>
        <span>Apply</span>
      </ng-template>
    </ion-button>
  </div>
</div>

<div *ngIf="showForm">
  <div class="back-to">
    <a (click)="goBack()"
      ><ion-icon class="icon-color" name="chevron-back-outline"></ion-icon>Back To Filter</a
    >
  </div>
  <div [ngSwitch]="selectedFilter">
    <div class="input-container" *ngSwitchCase="'price'">
      <ion-label>Price</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg price-filter-selection">
        <ion-select
          [(ngModel)]="selectedPrice"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
          class="select-dropdown"
        >
          <ion-select-option *ngFor="let PRICE of PRICE" [value]="PRICE.value"
            >{{ PRICE.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedPrice == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'volume'">
      <ion-label>Volume</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedVolume"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let VOLUME of VOLUME" [value]="VOLUME.value"
            >{{ VOLUME.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'isWeeklyStock'">
      <ion-label>Weekly Options</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedWeeklyOption"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let WEEKLYOPTION of WEEKLYOPTION" [value]="WEEKLYOPTION.value"
            >{{ WEEKLYOPTION.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'earnings'">
      <ion-label>Earnings</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedEarnings"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let EARNINGS of EARNINGS" [value]="EARNINGS.value"
            >{{ EARNINGS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'marketcap'">
      <ion-label>Market Cap</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedMarketCap"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let MARKET_CAP of MARKET_CAP" [value]="MARKET_CAP.value"
            >{{ MARKET_CAP.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'change'">
      <ion-label>Price Change</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedPriceChange"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option
            *ngFor="let PRICEFILTERSETUPDATA of PRICEFILTERSETUPDATA"
            [value]="PRICEFILTERSETUPDATA.name"
            >{{ PRICEFILTERSETUPDATA.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-label *ngIf="!!selectedPriceChange">Price Change {{ selectedPriceChange }}</ion-label>
      <ion-item *ngIf="!!selectedPriceChange" class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange1Day"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE1DAY of CHANGE1DAY" [value]="CHANGE1DAY.value"
            >{{ CHANGE1DAY.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange1Day == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input
              inputmode="text"
              [(ngModel)]="selectedMinValue1D"
              name="minPrice"
            ></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input
              inputmode="text"
              [(ngModel)]="selectedMaxValue1D"
              name="maxPrice"
            ></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <!-- <div class="input-container" *ngSwitchCase="'change7Days'">
      <ion-label>Price Change Last 7 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChangeLast7Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option
            *ngFor="let CHANGELAST7DAYS of CHANGELAST7DAYS"
            [value]="CHANGELAST7DAYS.value"
            >{{ CHANGELAST7DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChangeLast7Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change30Days'">
      <ion-label>Price Change 30 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange30Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE30DAYS of CHANGE30DAYS" [value]="CHANGE30DAYS.value"
            >{{ CHANGE30DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange30Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change60Days'">
      <ion-label>Price Change 60 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange60Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE60DAYS of CHANGE60DAYS" [value]="CHANGE60DAYS.value"
            >{{ CHANGE60DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange60Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change90Days'">
      <ion-label>Price Change 90 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange90Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE90DAYS of CHANGE90DAYS" [value]="CHANGE90DAYS.value"
            >{{ CHANGE90DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange90Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change180Days'">
      <ion-label>Price Change 180 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange180Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option
            *ngFor="let CHANGE180DAYS of CHANGE180DAYS"
            [value]="CHANGE180DAYS.value"
            >{{ CHANGE180DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange180Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change1YTD'">
      <ion-label>Price Change {{ priceChangeDateRange }}</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange1YTD"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let CHANGE1YTD of CHANGE1YTD" [value]="CHANGE1YTD.value"
            >{{ CHANGE1YTD.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange1YTD == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="input-container" *ngSwitchCase="'change365Days'">
      <ion-label>Price Change 365 Days</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedChange365Days"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option
            *ngFor="let CHANGE365DAYS of CHANGE365DAYS"
            [value]="CHANGE365DAYS.value"
            >{{ CHANGE365DAYS.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div class="between-price" *ngIf="selectedChange365Days == 'between'">
        <div class="min-price">
          <ion-label>Min Price </ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMinValue" name="minPrice"></ion-input>
          </ion-item>
        </div>
        <span>-</span>
        <div class="max-price">
          <ion-label>Max Price</ion-label>
          <ion-item class="ion-item-no-inner-padding hover-bg">
            <ion-input inputmode="text" [(ngModel)]="selectedMaxValue" name="maxPrice"></ion-input>
          </ion-item>
        </div>
      </div>
    </div> -->

    <div class="input-container" *ngSwitchCase="'country'">
      <ion-label>Country</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedCountry"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let COUNTRY of COUNTRY" [value]="COUNTRY.value"
            >{{ COUNTRY.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'industry'">
      <ion-label>Industry</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedIndustry"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let INDUSTRY of INDUSTRY" [value]="INDUSTRY.value"
            >{{ INDUSTRY.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'sector'">
      <ion-label>Sector</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selectedSector"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let SECTOR of SECTOR" [value]="SECTOR.value"
            >{{ SECTOR.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'50DAvgChg'">
      <ion-label>50D Avg Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected50DAvgChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let DAVGCHG50 of DAVGCHG50" [value]="DAVGCHG50.value"
            >{{ DAVGCHG50.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'200DAvgChg'">
      <ion-label>200D Avg Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected200DAvgChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let DAVGCHG200 of DAVGCHG200" [value]="DAVGCHG200.value"
            >{{ DAVGCHG200.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="input-container" *ngSwitchCase="'52WHighChg'">
      <ion-label>52W High Chg</ion-label>
      <ion-item class="ion-item-no-inner-padding hover-bg">
        <ion-select
          [(ngModel)]="selected52WHighChg"
          line="none"
          placeholder="Any"
          okText="Okay"
          cancelText="Dismiss"
          interface="popover"
        >
          <ion-select-option *ngFor="let WHIGHCHG52 of WHIGHCHG52" [value]="WHIGHCHG52.value"
            >{{ WHIGHCHG52.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="row choose-button">
      <!-- <ion-button type="submit" fill="solid" class="filter-cancel" (click)="goBack()"> -->
      <!-- Cancel -->
      <!-- </ion-button> -->
      <ion-button type="submit" color="primary" fill="solid" (click)="applyFilter()">
        Set
      </ion-button>
    </div>
  </div>
</div>

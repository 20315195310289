import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import {
  CHANGE180DAYS,
  CHANGE1DAY,
  CHANGE1YTD,
  CHANGE30DAYS,
  CHANGE365DAYS,
  CHANGE60DAYS,
  CHANGE90DAYS,
  CHANGELAST7DAYS,
  FILTERSETUPDATA,
  MARKET_CAP,
  PRICE,
  VOLUME,
} from 'src/app/utils/constant';
import { WatchListCreateAddModalComponent } from '../../tool-dashboard/watchlist-create-add-modal.component';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SetupScannerService } from 'src/app/services/setup-scanner.service';

@Component({
  selector: 'app-setup-scanner-data-listing',
  templateUrl: './setup-scanner-data-listing.component.html',
  styleUrls: ['./setup-scanner-data-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupScannerDataListingComponent implements OnInit {
  mode: string;
  lookupListVisibility: any;
  searchResult: any;
  showDatatable: boolean;
  showFilterCount: boolean = true;
  isDropdownOpen = false;
  rows: any;
  public tag = [];
  public page = new Page();
  public offset: number;
  pageSize = 50; // Number of items per page
  currentPage = 1;
  presetValue: {};
  symbolSearch: string;
  public isToolAccess = false;
  public lockedPageContent: null;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedChange1Day: string;
  // selectedChangeLast7Days: string;
  // selectedChange30Days: string;
  // selectedChange60Days: string;
  // selectedChange90Days: string;
  // selectedChange180Days: string;
  // selectedChange1YTD: string;
  // selectedChange635Days: string;
  selectedVolume: string;
  getSetupScannerData: string;
  chunkedData = [];
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE1DAY = CHANGE1DAY;
  CHANGELAST7DAYS = CHANGELAST7DAYS;
  CHANGE30DAYS = CHANGE30DAYS;
  CHANGE60DAYS = CHANGE60DAYS;
  CHANGE90DAYS = CHANGE90DAYS;
  CHANGE180DAYS = CHANGE180DAYS;
  CHANGE1YTD = CHANGE1YTD;
  CHANGE365DAYS = CHANGE365DAYS;
  VOLUME = VOLUME;
  filterDataObject = {};
  filterDataColorObject = {};
  filterCountShow = 0;
  isLoading = true;
  setupScannerRow: SetupScannerRow = {
    name: '',
    description: '',
    _id: '',
  };
  updatePresets: any;
  private closeMenuSubs: Subscription;
  private isMenuClosed: boolean = false;
  staticData = [
    {
      _id: '671909008099039009072932',
      name: 'Weekly Option Stocks',
      description: 'It refer to stocks that have options contracts with weekly expiration dates.',
      image: '/assets/icon/Rectangle 31.png',
    },
    {
      name: 'Surprise Factor',
      description:
        'A metric used to gauge the extent to which actual earnings or economic factor differ from market expectations',
      image: '/assets/icon/Rectangle 30.png',
    },
    {
      name: 'Crowbar',
      description:
        'Technical analysis pattern indicating a strong, sudden price movement breaking through resistance or support levels.',
      image: '/assets/icon/Rectangle 31 (1).png',
    },
    {
      name: 'Sunrise/Demise',
      description:
        'The Sunrise pattern indicates a potential bullish reversal, while the Demise pattern signals a potential bearish reversal.',
      image: '/assets/icon/Rectangle 31 (2).png',
    },
    {
      name: 'Stocks on the move',
      description: 'Stocks experiencing significant price changes or trading volume spikes.',
      image: '/assets/icon/Rectangle 32.png',
    },
    {
      name: 'Ideal setup',
      description:
        'A trading condition that aligns perfectly with a traders strategy for optimal potential profit',
      image: '/assets/icon/Rectangle 32 (1).png',
    },
  ];
  constructor(
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    private activatedRoute: ActivatedRoute,
    private storageServ: StorageService,
    public loaderService: LoaderService,
    private router: Router,
    private toastServ: ToastService,
    private toolsApi: ToolServiceService,
    public modalController: ModalController,
    public alertController: AlertController,
    private setupScannerService: SetupScannerService
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }
  role: any;
  tool: any;
  [x: string]: any;
  sortDirection = 'asc';
  public hideCryptoDetails: boolean = false;
  public CurrentPresets: boolean = false;
  public toolTitle: string;
  public toolIsReadOnlyForUser = false;
  public toolReadOnlyMessage = '';
  dataList = true;
  selectedPriceData: string;
  selectedVolumeData: string;
  selectedWeeklyOptionData: string;
  selectedEarningsData: string;
  selectedMarketCapData: string;
  selectedChangeData1Day: string;
  // selectedChangeDataLast7Days: string;
  // selectedChangeData30Days: string;
  // selectedChangeData60Days: string;
  // selectedChangeData90Days: string;
  // selectedChangeData180Days: string;
  // selectedChangeData1YTD: string;
  // selectedChangeData365Days: string;
  selectedCountryData: string;
  selectedIndustryData: string;
  selectedSectorData: string;
  selected50DAvgChgData: string;
  selected200DAvgChgData: string;
  selected52WHighChgData: string;
  filterArray: any[] = [];
  public receivedId: number = 0;
  currentID: number;
  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };

  ngOnInit() {
    this.getCloseFilter();
    this.activatedRoute.url.subscribe((urlSegment) => {
      const isProductTrue = this.router.url.includes('product/671909008099039009072931');
      this.hideCryptoDetails = isProductTrue;
    });
    this.activatedRoute.url.subscribe((urlSegment) => {
      const isProductId = this.router.url.includes('product/671909008099039009072932');
      this.CurrentPresets = isProductId;
    });
    this.setupScannerService.filterObject$.subscribe((filters) => {
      this.filterObject = filters;
      this.filterDataObject = filters;
    });
    this.getAllSymbolData(this.activatedRoute?.snapshot.params?.productName);
  }

  getCloseFilter() {
    this.menuService.getTriggerChildSetupScannerReset().subscribe((filterObject) => {
      const { data = [], meta, filterCount } = filterObject.filterObject;
      this.page = {
        offset: parseInt(meta.page, 10) - 1,
        size: meta?.perPage,
        totalElements: meta?.total,
        totalPages: meta?.pages,
      };
      this.rows = data;
      this.setupScannerRow.name = data.name ?? this.setupScannerRow.name;
      this.setupScannerRow.description = data.description ?? this.setupScannerRow.description;
      this.filterCountShow = filterCount;
      if (
        !this.areObjectsEqual(
          this.removeUndefinedValuesFromObj(this.filterDataObject),
          this.removeUndefinedValuesFromObj(this.filterDataColorObject)
        )
      ) {
        this.isFilterActive = true;
      } else {
        this.isFilterActive = false;
      }
    });
  }

  areObjectsEqual(obj1, obj2) {
    const key1 = Object.keys(obj1);
    const key2 = Object.keys(obj2);

    if (key1.length != key2.length) return false;
    return key1.every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
  }

  removeUndefinedValuesFromObj(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value != undefined));
  }

  getStock() {
    this.setupScannerService.currentId.subscribe((id) => {
      this.receivedId = id;
      this.currentID = this.receivedId;

      const updatePayload = {
        _id: this.currentID,
        presets: this.filterObject,
      };
      this.setupScannerService
        .updateSetupScanner(this.currentID, updatePayload)
        .subscribe((res: any) => {
          if (res) {
          }
        });
    });
  }

  sendId(id) {
    this.setupScannerService.changeId(id); // Send the id to the service
  }

  getAllSymbolData(row) {
    if (this.symbolSearch) {
      this.searchText = this.symbolSearch;
    }
    this.setupScannerService.getAllStockDetails(row).subscribe((res: any) => {
      const currentName = this.setupScannerRow?.name;
      const currentId = this.setupScannerRow?._id;
      const presets = res.data.presets || {};
      this.presetValue = res.data.presets;
      const presetsLength = Object.keys(presets).filter((key) => presets[key] !== null).length;
      this.filterCountShow = presetsLength;
      this.sendId(currentId);
      this.setupScannerRow = {
        ...res?.data,
        name: currentName || res?.data?.name,
        presets: this.filterObject,
      };
      this.filterDataColorObject = res.data.presets;
      this.filterDataObject = {
        ...res.data.presets,
        page: 1,
        pageSize: 50,
      };
      this.setupScannerService.updateFilterObject(this.filterDataObject);
      this.tradeToolsService
        .setupScannerWeeklyStock(this.filterDataObject)
        .subscribe((result: any) => {
          const { data = [], meta } = result;
          this.page = {
            offset: parseInt(meta.page, 10) - 1,
            size: meta?.perPage,
            totalElements: meta?.total,
            totalPages: meta?.pages,
          };
          this.rows = data;
          this.isLoading = false;
        });
    });
  }

  setFilterParams(filterObject) {
    this.selectedPrice = filterObject['price'];
    this.selectedVolume = filterObject['volume'];
    this.selectedEarnings = filterObject['earnings'];
    this.selectedMarketCap = filterObject['marketCap'];
    this.selectedChange1Day = filterObject['change1Day'];
    // this.selectedChangeLast7Days = filterObject['change7Days'];
    // this.selectedChange30Days = filterObject['change30Days'];
    // this.selectedChange60Days = filterObject['change60Days'];
    // this.selectedChange90Days = filterObject['change90Days'];
    // this.selectedChange180Days = filterObject['change180Days'];
    // this.selectedChange1YTD = filterObject['change1YTD'];
    // this.selectedChange365Days = filterObject['change365Days'];
  }

  async createEditSetupScanner(mode) {
    this.mode = mode;
    let setupScannerProps: any = {
      mode,
      componentType: 'SetupScannerAddUpdateComponent',
    };
    const activeToolSlug = this.activeToolSlug;
    setupScannerProps.activeToolSlug = activeToolSlug;
    if (mode === 'edit') {
      setupScannerProps.editData = this.setupScannerRow;
    }
    this.menuService.openRightMenu(setupScannerProps);
  }

  onSelectChange(event: any) {
    if (event.detail.value === 'saveNewPreset') {
      this.createEditSetupScanner('add');
    }
    if (event.detail.value === 'saveCurrentPreset') {
      this.showFilterCount = !this.showFilterCount;
      const name = this.setupScannerRow.name;
      const description = this.setupScannerRow.description;
      const setupScannerId = this.setupScannerRow._id;
      const presets = this.filterObject;
      const updatePayload = {
        name: name,
        description: description,
        setupScannerId: setupScannerId,
        presets: presets,
      };
      this.setupScannerService
        .updateSetupScanner(setupScannerId, updatePayload)
        .subscribe((res: any) => {
          this.updatePresets = res?.data;
        });
      this.getCloseFilter();
      this.getAllSymbolData(setupScannerId);
      window.location.reload();
    }
  }

  viewSymbol(symbol: string) {
    this.toolsApi.setContext('setup-scanner');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
  }

  toggleDropdown(event: Event, row: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log('row', row);
    // Close dropdown if clicking on a different row
    if (this.selectedRow === row) {
      this.isDropdownOpen = !this.isDropdownOpen;
    } else {
      this.isDropdownOpen = true;
      this.selectedRow = row;
    }
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  back() {
    window.location.reload();
  }

  getHeaderClassChangePercent({ row }): any {
    return {
      'custom-cell-green': Number(row.regularMarketChangePercent) >= 0,
      'custom-cell-red': Number(row.regularMarketChangePercent) < 0,
    };
  }

  applyFilterList(offset = this.page.offset) {
    this.isLoading = true;
    let filterObject = {
      page: offset + 1,
      pageSize: this.page.size,
    };
    FILTERSETUPDATA.forEach((item) => {
      if (item.currentValue === this.selectedPriceData) {
        filterObject['price'] = this.selectedPriceData ? this.selectedPriceData : null;
      }
      if (item.currentValue === this.selectedVolumeData) {
        filterObject['volume'] = this.selectedVolumeData ? this.selectedVolumeData : null;
      }
      if (item.currentValue === this.selectedWeeklyOptionData) {
        filterObject['isWeeklyStock'] = this.selectedWeeklyOptionData
          ? this.selectedWeeklyOptionData
          : null;
      }
      if (item.currentValue === this.selectedEarningsData) {
        filterObject['earnings'] = this.selectedEarningsData ? this.selectedEarningsData : null;
      }
      if (item.currentValue === this.selectedMarketCapData) {
        filterObject['marketCap'] = this.selectedMarketCapData ? this.selectedMarketCapData : null;
      }
      if (item.currentValue === this.selectedChangeData1Day) {
        filterObject['change1Day'] = this.selectedChangeData1Day
          ? this.selectedChangeData1Day
          : null;
      }
      // if (item.currentValue === this.selectedChangeDataLast7Days) {
      //   filterObject['change7Days'] = this.selectedChangeDataLast7Days
      //     ? this.selectedChangeDataLast7Days
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData30Days) {
      //   filterObject['change30Days'] = this.selectedChangeData30Days
      //     ? this.selectedChangeData30Days
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData60Days) {
      //   filterObject['change60Days'] = this.selectedChangeData60Days
      //     ? this.selectedChangeData60Days
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData90Days) {
      //   filterObject['change90Days'] = this.selectedChangeData90Days
      //     ? this.selectedChangeData90Days
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData180Days) {
      //   filterObject['change180Days'] = this.selectedChangeData180Days
      //     ? this.selectedChangeData180Days
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData1YTD) {
      //   filterObject['change1YTD'] = this.selectedChangeData1YTD
      //     ? this.selectedChangeData1YTD
      //     : null;
      // }
      // if (item.currentValue === this.selectedChangeData365Days) {
      //   filterObject['change365Days'] = this.selectedChangeData365Days
      //     ? this.selectedChangeData365Days
      //     : null;
      // }
      if (item.currentValue === this.selectedCountryData) {
        filterObject['country'] = this.selectedCountryData ? this.selectedCountryData : null;
      }
      if (item.currentValue === this.selectedIndustryData) {
        filterObject['industry'] = this.selectedIndustryData ? this.selectedIndustryData : null;
      }
      if (item.currentValue === this.selectedSectorData) {
        filterObject['sector'] = this.selectedSectorData ? this.selectedSectorData : null;
      }
      if (item.currentValue === this.selected50DAvgChgData) {
        filterObject['50DAvgChg'] = this.selected50DAvgChgData ? this.selected50DAvgChgData : null;
      }
      if (item.currentValue === this.selected200DAvgChgData) {
        filterObject['200DAvgChg'] = this.selected200DAvgChgData
          ? this.selected200DAvgChgData
          : null;
      }
      if (item.currentValue === this.selected52WHighChgData) {
        filterObject['52WHighChg'] = this.selected52WHighChgData
          ? this.selected52WHighChgData
          : null;
      }
      if (this.searchText && this.searchText.trim() !== '') {
        filterObject['symbolSearch'] = this.searchText.trim();
      } else {
        delete this.filterDataObject['symbolSearch'];
      }
    });
    this.dataList = false;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.isLoading = false;
      });
  }

  private calculateFilterCount(): number {
    let count = 0;
    if (this.selectedPrice && this.selectedPrice !== 'any') count++;
    if (this.selectedVolume && this.selectedVolume !== 'any') count++;
    if (this.selectedWeeklyOption && this.selectedWeeklyOption !== 'any') count++;
    if (this.selectedEarnings && this.selectedEarnings !== 'any') count++;
    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') count++;
    if (this.selectedChange1Day && this.selectedChange1Day !== 'any') count++;
    // if (this.selectedChangeLast7Days && this.selectedChangeLast7Days !== 'any') count++;
    // if (this.selectedChange30Days && this.selectedChange30Days !== 'any') count++;
    // if (this.selectedChange60Days && this.selectedChange60Days !== 'any') count++;
    // if (this.selectedChange90Days && this.selectedChange90Days !== 'any') count++;
    // if (this.selectedChange180Days && this.selectedChange180Days !== 'any') count++;
    // if (this.selectedChange1YTD && this.selectedChange1YTD !== 'any') count++;
    // if (this.selectedChange365Days && this.selectedChange365Days !== 'any') count++;
    if (this.selectedCountry && this.selectedCountry !== 'any') count++;
    if (this.selectedIndustry && this.selectedIndustry !== 'any') count++;
    if (this.selectedSector && this.selectedSector !== 'any') count++;
    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') count++;
    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') count++;
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') count++;
    return count;
  }

  sortDataPrice() {
    const sortedData = [...this.rows].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketPrice - b.regularMarketPrice;
      } else {
        return b.regularMarketPrice - a.regularMarketPrice;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }
  sortDataChange() {
    const sortedData = [...this.rows].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChangePercent - b.regularMarketChangePercent;
      } else {
        return b.regularMarketChangePercent - a.regularMarketChangePercent;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  sortDataMarketCap() {
    const sortedData = [...this.rows].sort((a, b) => {
      const marketCapA = this.parseMarketCap(a.marketCap);
      const marketCapB = this.parseMarketCap(b.marketCap);

      if (this.sortDirection === 'asc') {
        return marketCapA - marketCapB;
      } else {
        return marketCapB - marketCapA;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  parseMarketCap(marketCap: string): number {
    const value = parseFloat(marketCap);
    if (marketCap.includes('T')) {
      return value * 1e12; // Trillions
    } else if (marketCap.includes('B')) {
      return value * 1e9; // Billions
    } else if (marketCap.includes('M')) {
      return value * 1e6; // Millions
    } else if (marketCap.includes('K')) {
      return value * 1e3; // Thousands
    } else {
      return value; // Raw number
    }
  }
  sortDataVolume() {
    const sortedData = [...this.rows].sort((a, b) => {
      const volumeA = parseInt(a.regularMarketVolume.replace(/,/g, ''), 10);
      const volumeB = parseInt(b.regularMarketVolume.replace(/,/g, ''), 10);

      if (this.sortDirection === 'asc') {
        return volumeA - volumeB;
      } else {
        return volumeB - volumeA;
      }
    });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = sortedData;
  }

  async addToWatchlist(symbol: string) {
    const modal = await this.modalController.create({
      component: WatchListCreateAddModalComponent,
      componentProps: {
        selectedSymbol: symbol,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.toolsApi.addWatchList(data).subscribe((response: any) => {
          if (response && response.message) {
            this.toastServ.presentToast(response.message, 'success');
          }
        });
      }
    });
    return await modal.present();
  }

  openRightMenu() {
    this.destroyInterval();
    const filterData = {
      componentType: 'FilterSetupScannerListComponent',
    };
    this.menuService.openRightMenu(filterData);
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  async deleteSetupScanner() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure you want to delete this scanner?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.removeSetupScanner();
          },
        },
      ],
    });
    await alert.present();
  }

  removeSetupScanner() {
    this.toolsApi.removeSetupScanner(this.setupScannerRow?._id).subscribe((response: any) => {
      if (response) {
        this.toastServ.presentToast(response.message, 'success');
        this.viewRemoveSetupScanner();
      }
    });
  }

  viewRemoveSetupScanner() {
    this.menuService.triggerResetSetupScanner();
    this.router.navigate([`/${this.role}/tools/setup-scanner/${this.activeToolSlug}`]);
  }

  onClearSearch() {
    this.searchText = '';
    this.applyFilterList();
  }

  onSearchCategory(ev: CustomEvent, type: string) {
    this.searchText = type === 'filter' && ev.detail.value === 'All' ? null : ev.detail.value;
    if (this.searchText) {
      this.applyFilterList();
    }
  }

  getWatchlistStockDetails() {
    this.isLoading = true;
    if (this.filterStockByName) {
      this.searchText = this.filterStockByName;
    }
    this.toolsApi.getWatchListStockDetails(this.searchText).subscribe((response: any) => {
      const { data = [], meta } = response;
      this.page = {
        offset: parseInt(meta.page, 10) - 1,
        size: meta?.perPage,
        totalElements: meta?.total,
        totalPages: meta?.pages,
      };
      this.rows = data;
      this.isLoading = false;
    });
  }

  backToSetupScanner() {
    this.router
      .navigate([`/${this.role}/tools/setup-scanner/${this.activeToolSlug}`], {
        state: { fromCryptoDetails: true },
      })
      .then(() => {
        window.location.reload();
      });
  }
}

export interface SetupScannerRow {
  name: string;
  description: string;
  _id: string;
}

<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ion-row class="tracker-details-row tracker-details-responsive">
      <ion-col size="4" class="tracker-details-col dashboard-nav">
        <div>
          <ol class="cd-breadcrumb custom-separator custom-icons">
            <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
            <li>
              <a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools Dashboard</a>
            </li>
            <li class="current">
              {{ selectedStockDetails?.symbol | uppercase }}
            </li>
          </ol>
        </div>
      </ion-col>
      <ion-col size="6" class="tracker-details-col dashboard-search">
        <div class="loading-spinner" *ngIf="isLoading"></div>
        <ion-searchbar
          class="searchbar-green"
          search-icon="search-outline"
          debounce="1000"
          id="search"
          placeholder="Quote Lookup"
          (ionChange)="onSearchLookupNew($event)"
          (keydown)="handleKeyDown($event)"
          [value]="
            selectedLookup.symbol && selectedLookup.name
              ? selectedLookup.symbol + ' - ' + selectedLookup.name
              : ''
          "
        >
        </ion-searchbar>
        <div
          class="taglist-search"
          *ngIf="
            !isLoading &&
            lookupListVisibility &&
            !selectedLookup?.symbol &&
            searchResult &&
            searchResult.length > 0
          "
        >
          <span class="close" (click)="hideLookupList()">&#10005;</span>
          <p
            *ngFor="let item of searchResult; let i = index"
            (click)="addLookup(item.symbol)"
            [class.selected]="i === highlightedIndex"
            tabindex="0"
          >
            {{ item.symbol }} - {{ item.name }}
          </p>
        </div>
      </ion-col>
    </ion-row>
    <div>
      <div class="page-heading">
        <span
          *ngIf="context === 'dashboard'"
          class="back-trade"
          routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}"
        >
          < Back To Tool Dashboard</span
        >
        <span *ngIf="context === 'watchlist'" class="back-trade" (click)="goBack()">
          < Back To Watchlists
        </span>
        <span *ngIf="context === 'setup-scanner'" class="back-trade" (click)="goBack()">
          < Back To Setup-Scanner
        </span>
        <ion-button fill="outline" *ngIf="!watchlistCount" (click)="toggleButtonColor()">
          <ion-icon slot="start" name="add-outline"></ion-icon> Add To Watchlist
        </ion-button>
        <ion-button
          class="watchlist-btn"
          fill="outline"
          *ngIf="watchlistCount"
          (click)="toggleButtonColor()"
        >
          Watchlist
          <span class="number-round">{{ watchlistCount }}</span>
        </ion-button>
      </div>
      <ion-row class="tracker-details-row dashboad-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="12"
          size-lg="12"
          class="tracker-details-col"
        >
          <div class="share-details search-details">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding share-details-header">
                <div class="page-heading custom-style">
                  <div class="heading-area">
                    <div
                      class="action"
                      *ngIf="selectedStockDetails?.stockUrl"
                      style="display: none"
                    >
                      <span class="logo"
                        ><img [src]="selectedStockDetails?.stockUrl" alt="" srcset=""
                      /></span>
                    </div>
                    <div class="action" *ngIf="iframeSrc && !selectedStockDetails?.stockUrl">
                      <span class="logo log-dynamic"
                        ><img [src]="iframeSrc" alt="" srcset=""
                      /></span>
                    </div>
                    <div class="title-wrap" *ngIf="selectedStockDetails?.symbol">
                      <h2>
                        {{ selectedStockDetails?.shortName }}({{ selectedStockDetails?.symbol }})
                      </h2>
                      <h4>
                        {{ selectedStockDetails?.fullExchangeName }} :
                        {{ selectedStockDetails?.shortName }}
                      </h4>
                    </div>
                  </div>
                  <div class="hedaing-rates-section">
                    <div class="at-close-section rate-details-sec">
                      <div class="text-wrap-sec">
                        <span class="large-text">{{
                          selectedStockDetails?.regularMarketPrice | number : '1.2-2'
                        }}</span
                        ><span
                          [ngClass]="
                            getHeaderClassTodayChange1({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.regularMarketChangePercent | number : '1.2-2' }}%
                          ({{ selectedStockDetails?.regularMarketChange | number : '1.2-2' }})</span
                        >
                      </div>
                      <p
                        class="text-data-wrap"
                        *ngIf="selectedStockDetails?.marketState !== 'REGULAR'"
                      >
                        <span>At close:</span>
                        {{
                          selectedStockDetails?.regularMarketTime * 1000
                            | date
                              : 'MMMM d hh:mma'
                              : selectedStockDetails?.exchangeTimezoneShortName
                        }}
                        {{ selectedStockDetails?.exchangeTimezoneShortName }}
                      </p>

                      <p
                        class="text-data-wrap"
                        *ngIf="selectedStockDetails?.marketState === 'REGULAR'"
                      >
                        <span>Market Open:</span>
                        {{
                          selectedStockDetails?.regularMarketTime * 1000
                            | date : 'MMMM d' : selectedStockDetails?.exchangeTimezoneShortName
                        }}
                      </p>
                    </div>
                    <div
                      class="at-after-section rate-details-sec"
                      *ngIf="selectedStockDetails?.postMarketPrice"
                    >
                      <div class="text-wrap-sec">
                        <span class="large-text size-small"
                          >{{ selectedStockDetails?.postMarketPrice | number : '1.2-2' }} </span
                        ><span
                          [ngClass]="
                            getHeaderClassPostChange1({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >{{ selectedStockDetails?.postMarketChangePercent | number : '1.2-2' }}%
                          ({{ selectedStockDetails?.postMarketChange | number : '1.2-2' }})</span
                        >
                      </div>
                      <p class="text-data-wrap">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.49023 8.76265C2.49023 12.4906 5.51232 15.5127 9.24023 15.5127C12.0806 15.5127 14.5112 13.7583 15.5076 11.274C14.7302 11.5865 13.8794 11.7626 12.9902 11.7626C9.26228 11.7626 6.24023 8.74053 6.24023 5.01259C6.24023 4.12756 6.41374 3.27197 6.7234 2.4975C4.24204 3.49524 2.49023 5.92437 2.49023 8.76265Z"
                            stroke="#60A5FA"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>After hours:</span>
                        {{
                          selectedStockDetails?.postMarketTime * 1000
                            | date
                              : 'MMMM d hh:mma'
                              : selectedStockDetails?.exchangeTimezoneShortName
                        }}
                        {{ selectedStockDetails?.exchangeTimezoneShortName }}
                      </p>
                    </div>

                    <div
                      class="at-after-section rate-details-sec"
                      *ngIf="selectedStockDetails?.preMarketPrice"
                    >
                      <div class="text-wrap-sec">
                        <span class="large-text size-small"
                          >{{ selectedStockDetails?.preMarketPrice | number : '1.2-2' }} </span
                        ><span
                          [ngClass]="
                            getHeaderClassPreChange1({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >{{ selectedStockDetails?.preMarketChangePercent | number : '1.2-2' }}%
                          ({{ selectedStockDetails?.preMarketChange | number : '1.2-2' }})</span
                        >
                      </div>
                      <p class="text-data-wrap">
                        <!-- <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.49023 8.76265C2.49023 12.4906 5.51232 15.5127 9.24023 15.5127C12.0806 15.5127 14.5112 13.7583 15.5076 11.274C14.7302 11.5865 13.8794 11.7626 12.9902 11.7626C9.26228 11.7626 6.24023 8.74053 6.24023 5.01259C6.24023 4.12756 6.41374 3.27197 6.7234 2.4975C4.24204 3.49524 2.49023 5.92437 2.49023 8.76265Z"
                            stroke="#60A5FA"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> -->
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke="#FB8C00"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.5 0C7.77614 0 8 0.223858 8 0.5V2.5C8 2.77614 7.77614 3 7.5 3C7.22386 3 7 2.77614 7 2.5V0.5C7 0.223858 7.22386 0 7.5 0ZM2.1967 2.1967C2.39196 2.00144 2.70854 2.00144 2.90381 2.1967L4.31802 3.61091C4.51328 3.80617 4.51328 4.12276 4.31802 4.31802C4.12276 4.51328 3.80617 4.51328 3.61091 4.31802L2.1967 2.90381C2.00144 2.70854 2.00144 2.39196 2.1967 2.1967ZM0.5 7C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H2.5C2.77614 8 3 7.77614 3 7.5C3 7.22386 2.77614 7 2.5 7H0.5ZM2.1967 12.8033C2.00144 12.608 2.00144 12.2915 2.1967 12.0962L3.61091 10.682C3.80617 10.4867 4.12276 10.4867 4.31802 10.682C4.51328 10.8772 4.51328 11.1938 4.31802 11.3891L2.90381 12.8033C2.70854 12.9986 2.39196 12.9986 2.1967 12.8033ZM12.5 7C12.2239 7 12 7.22386 12 7.5C12 7.77614 12.2239 8 12.5 8H14.5C14.7761 8 15 7.77614 15 7.5C15 7.22386 14.7761 7 14.5 7H12.5ZM10.682 4.31802C10.4867 4.12276 10.4867 3.80617 10.682 3.61091L12.0962 2.1967C12.2915 2.00144 12.608 2.00144 12.8033 2.1967C12.9986 2.39196 12.9986 2.70854 12.8033 2.90381L11.3891 4.31802C11.1938 4.51328 10.8772 4.51328 10.682 4.31802ZM8 12.5C8 12.2239 7.77614 12 7.5 12C7.22386 12 7 12.2239 7 12.5V14.5C7 14.7761 7.22386 15 7.5 15C7.77614 15 8 14.7761 8 14.5V12.5ZM10.682 10.682C10.8772 10.4867 11.1938 10.4867 11.3891 10.682L12.8033 12.0962C12.9986 12.2915 12.9986 12.608 12.8033 12.8033C12.608 12.9986 12.2915 12.9986 12.0962 12.8033L10.682 11.3891C10.4867 11.1938 10.4867 10.8772 10.682 10.682ZM5.5 7.5C5.5 6.39543 6.39543 5.5 7.5 5.5C8.60457 5.5 9.5 6.39543 9.5 7.5C9.5 8.60457 8.60457 9.5 7.5 9.5C6.39543 9.5 5.5 8.60457 5.5 7.5ZM7.5 4.5C5.84315 4.5 4.5 5.84315 4.5 7.5C4.5 9.15685 5.84315 10.5 7.5 10.5C9.15685 10.5 10.5 9.15685 10.5 7.5C10.5 5.84315 9.15685 4.5 7.5 4.5Z"
                          />
                        </svg>
                        <!-- <span>After hours:</span> -->
                        <span>Pre-market:</span>
                        {{
                          selectedStockDetails?.preMarketTime * 1000
                            | date
                              : 'MMMM d hh:mma'
                              : selectedStockDetails?.exchangeTimezoneShortName
                        }}
                        {{ selectedStockDetails?.exchangeTimezoneShortName }}
                      </p>
                    </div>
                  </div>
                  <div
                    style="display: none"
                    class="ion-flex-container ion-align-items-left ion-flex-column custom-rate-style"
                  >
                    <div class="custom-size">
                      <span class="font-custom-size"
                        >${{ selectedStockDetails?.regularMarketPrice }}23</span
                      >
                    </div>
                    <div class="close-at-wrap">
                      <div class="close-symbol" *ngIf="selectedStockDetails?.regularMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.regularMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.regularMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassTodayChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.regularMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassTodayChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{
                            selectedStockDetails?.regularMarketChangePercent | number : '1.2-2'
                          }}%)
                        </span>
                        <span class="text-day">Today</span>
                      </div>
                    </div>

                    <div class="close-at-wrap">
                      <div class="close-symbol" *ngIf="selectedStockDetails?.postMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.postMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.postMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassPostChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.postMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassPostChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{ selectedStockDetails?.postMarketChangePercent | number : '1.2-2' }}%)
                        </span>
                        <span class="text-day"
                          >After Hours
                          <ion-icon name="moon" class="close-symbol-icon"></ion-icon>
                        </span>
                      </div>

                      <div class="close-symbol" *ngIf="selectedStockDetails?.preMarketPrice">
                        <ng-container *ngIf="selectedStockDetails?.preMarketChangePercent > 0">
                          <ion-icon
                            name="caret-up-outline"
                            color="primary"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="selectedStockDetails?.preMarketChangePercent < 0">
                          <ion-icon
                            name="caret-down-outline"
                            color="danger"
                            style="font-size: 20px"
                          ></ion-icon>
                        </ng-container>
                        <span
                          class="custom-size2"
                          [ngClass]="
                            getHeaderClassPreChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                        >
                          {{ selectedStockDetails?.preMarketChange | number : '1.2-2' }}
                        </span>
                        <span
                          [ngClass]="
                            getHeaderClassPreChange({
                              selectedStockDetails: selectedStockDetails
                            })
                          "
                          >({{ selectedStockDetails?.preMarketChangePercent | number : '1.2-2' }}%)
                        </span>
                        <span class="text-day"
                          >After Hours
                          <ion-icon name="sun" class="close-symbol-icon"></ion-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full overflow-x-auto">
                  <ion-segment (ionChange)="segmentChanged($event)" [value]="defaultView">
                    <ion-segment-button class="css-font" value="stockInfo">
                      <ion-label> Stock Info </ion-label>
                    </ion-segment-button>
                    <ion-segment-button class="css-font" value="aboutStock">
                      <ion-label> About Stock </ion-label>
                    </ion-segment-button>
                    <ion-segment-button
                      class="css-font"
                      value="latestNews"
                      (click)="onRecentNews(selectedStockDetails?.symbol)"
                    >
                      <ion-label> Latest News </ion-label>
                    </ion-segment-button>
                    <ion-segment-button
                      class="css-font"
                      value="tradeHistory"
                      (click)="getTradeHistoryList()"
                    >
                      <ion-label> Trade History </ion-label>
                    </ion-segment-button>
                    <ion-segment-button
                      class="css-font badge-green"
                      value="alert"
                      (click)="getAlertList()"
                    >
                      <ion-label> Alerts </ion-label>
                      <div *ngIf="alertCount !== 0" class="badge">
                        <span class="number-round">{{ alertCount }}</span>
                      </div>
                    </ion-segment-button>
                    <ion-segment-button
                      class="css-font badge-green"
                      value="notes"
                      (click)="getSymbolWiseNotes(selectedStockDetails?.symbol)"
                    >
                      <ion-label> Notes </ion-label>
                      <div *ngIf="notesCount !== 0" class="badge">
                        <span class="number-round">{{ notesCount }}</span>
                      </div>
                    </ion-segment-button>
                  </ion-segment>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="segment === 'stockInfo'" class="tracker-details-row dashboad-row tracker-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="8"
          size-lg="8"
          class="tracker-details-col"
        >
          <div class="share-details search-details content-block">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding custom-style">
                <div class="item-block item-scrollbar-block">
                  <div class="tradingview-widget-container">
                    <div #hotlistsymbol class="tradingview-widget-container__widget"></div>
                    <div class="tradingview-widget-copyright">
                      <a href="https://in.tradingview.com/" rel="noopener" target="_blank"></a>
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="4"
          size-lg="4"
          class="tracker-details-col"
        >
          <div class="account-details ion-no-padding account-block">
            <ion-card class="ion-no-margin rounded-area" style="height: 100%">
              <h3 style="margin: 15px 0px 0px 19px">Stock Details</h3>
              <ion-card-content class="ion-no-padding">
                <ion-list lines="full" class="list-ticker-portfolio-details">
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Weekly Options</span>
                        <span
                          class="weekly-option"
                          [ngClass]="{
                            yes: selectedStockDetails?.isWeeklyStock,
                            no: !selectedStockDetails?.isWeeklyStock
                          }"
                        >
                          <ion-icon
                            [name]="
                              selectedStockDetails?.isWeeklyStock
                                ? 'checkmark-circle'
                                : 'close-circle'
                            "
                          ></ion-icon>
                          {{ selectedStockDetails?.isWeeklyStock ? 'Yes' : 'No' }}
                        </span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Prev. Close</span>
                        <span>{{ selectedStockDetails?.regularMarketPreviousClose }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Open</span>
                        <span>{{ selectedStockDetails?.regularMarketOpen }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Day Range</span>
                        <span>{{ selectedStockDetails?.regularMarketDayRange }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">52 Week Range</span>
                        <span>{{ selectedStockDetails?.fiftyTwoWeekRange }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Volume</span>
                        <span>{{
                          selectedStockDetails?.regularMarketVolume | number : '1.0-0'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Avg. volume</span>
                        <span>{{
                          selectedStockDetails?.averageDailyVolume10Day | number : '1.0-0'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Market Cap</span>
                        <span>{{ convertMarketCapToWords(selectedStockDetails?.marketCap) }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Beta (5Y Monthly)</span>
                        <span>{{ convertMarketCapToWords(selectedStockDetails?.marketCap) }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Earnings Date</span>
                        <span>{{
                          selectedStockDetails?.earningsTimestampStart * 1000
                            | date : 'MMM dd, yyyy'
                        }}</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item lines="none">
                    <div
                      class="detail-container label"
                      [ngClass]="{
                        confirmed: selectedStockDetails?.earning_confirmed,
                        'not-confirmed': !selectedStockDetails?.earning_confirmed
                      }"
                    >
                      <div class="details">
                        <span class="value-opacity">Earnings Confirmed/Unconfirmed</span>
                        <span
                          class="earnings-status"
                          [ngClass]="{
                            confirmed: selectedStockDetails?.earning_confirmed,
                            'not-confirmed': !selectedStockDetails?.earning_confirmed
                          }"
                        >
                          <ion-icon
                            [name]="
                              selectedStockDetails?.earning_confirmed
                                ? 'checkmark-outline'
                                : 'close-outline'
                            "
                          ></ion-icon>
                          {{
                            selectedStockDetails?.earning_confirmed ? 'Confirmed' : 'Unconfirmed'
                          }}
                        </span>
                        <div
                          *ngIf="selectedStockDetails?.earning_confirmed"
                          class="earnings-subtext"
                        >
                          Before the open
                        </div>
                        <div
                          *ngIf="!selectedStockDetails?.earning_confirmed"
                          class="earnings-subtext"
                        >
                          After the close
                        </div>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Forward Dividend & Yield</span>
                        <span *ngIf="selectedStockDetails?.dividendYield"
                          >{{ selectedStockDetails?.dividendRate?.toFixed(2) }}({{
                            selectedStockDetails?.dividendYield
                          }})</span
                        >
                        <span *ngIf="!selectedStockDetails?.dividendYield">-</span>
                      </div>
                    </div>
                  </ion-item>
                  <ion-item>
                    <div class="detail-container">
                      <div class="details">
                        <span class="value-opacity">Ex-Dividend Date</span>
                        <span>{{
                          selectedStockDetails?.dividendDate * 1000 | date : 'MMM dd, yyyy'
                        }}</span>
                        <span *ngIf="!selectedStockDetails?.dividendDate">-</span>
                      </div>
                    </div>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="tracker-details-row dashboad-row">
        <ion-col
          size-xs="12"
          size-sm="12"
          size-md="12"
          size-xl="12"
          size-lg="12"
          class="tracker-details-col"
        >
          <div class="share-details search-details">
            <ion-card class="ion-no-margin card-container">
              <ion-card-content class="ion-no-padding width-css">
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css about-stock"
                  *ngIf="segment === 'aboutStock'"
                >
                  <ion-item class="no-border">
                    <span>{{ selectedStockDetails?.summary }}</span>
                  </ion-item>
                </ion-list>

                <ion-list
                  class="animate__animated animate__slideInUp animate__fast latest-news"
                  *ngIf="segment === 'latestNews'"
                >
                  <ion-col>
                    <div class="card-container">
                      <div class="custom-card custom-card-block news-wrap-section">
                        <div class="card-content">
                          <div class="product-wrapper">
                            <div>
                              <div
                                class="loading-spinner news-wrap-section"
                                *ngIf="isLoading"
                              ></div>
                              <ngx-datatable
                                #externalSpendTable
                                class="material body-cell-stacked"
                                [rows]="selectedNews"
                                [columnMode]="'force'"
                                [rowHeight]="'auto'"
                                [headerHeight]="50"
                                [footerHeight]="40"
                                [externalPaging]="true"
                                [scrollbarH]="true"
                                [count]="page.totalElements"
                                [offset]="page.offset"
                                [limit]="page.size"
                                *ngIf="!isLoading"
                              >
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-title="row.title"
                                    ngx-datatable-cell-template
                                  >
                                    <span>
                                      {{ title }}
                                    </span>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [sortable]="false">
                                  <ng-template
                                    let-row="row"
                                    let-link="row.link"
                                    let-pubDate="row.pubDate"
                                    ngx-datatable-cell-template
                                  >
                                    <span class="recent-news-name" (click)="link && openLink(link)">
                                      Source
                                    </span>
                                    <span class="pub-date"> • {{ pubDate | date : 'h:mm a' }}</span>
                                  </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-list>
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css"
                  *ngIf="segment === 'tradeHistory'"
                >
                  <ion-item class="width-css alert-heading-item">
                    <div class="page-heading">
                      <h2>Trade History</h2>
                    </div>
                  </ion-item>
                  <div
                    class="trade-history-wrap trade-history-section"
                    *ngIf="tradeHistoryList.length > 0"
                  >
                    <div
                      *ngFor="let data of tradeHistoryList"
                      class="trade-history-block"
                      (click)="onClickHistory(data)"
                    >
                      <div class="trader-data">
                        <span>Trade Type:</span>
                        <span class="value">{{ data.tradeType }}</span>
                      </div>
                      <div class="trader-data">
                        <span>Date Opened :</span>
                        <span class="value">{{ data.createdAt | date : 'd MMM yy' }}</span>
                      </div>
                      <div class="trader-data">
                        <span>Trade Duration :</span>
                        <span class="value"
                          >{{
                            calculateDaysDifference(
                              data.createdAt,
                              data.tradeCloseDate,
                              data.status
                            )
                          }}
                          Days</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>POS. P/L :</span>
                        <div *ngIf="data.cumulativeProfitLoss < 0">
                          <span class="value red-text">{{
                            data.cumulativeProfitLoss | currency
                          }}</span>
                        </div>
                        <div *ngIf="data.cumulativeProfitLoss > 0">
                          <span class="value green-text"
                            >+{{ data.cumulativeProfitLoss | currency }}</span
                          >
                        </div>
                        <div *ngIf="data.cumulativeProfitLoss == 0">
                          <span class="value">{{ data.cumulativeProfitLoss | currency }}</span>
                        </div>
                      </div>
                      <div class="trader-data">
                        <span>Notes :</span>
                        <span class="value">
                          <ion-icon slot="start" name="document-text-outline"></ion-icon>
                          {{ data.totalNotes }} Note</span
                        >
                      </div>
                      <div class="trader-data">
                        <span>Status :</span>
                        <ion-button class="open-btn">{{ data.status }}</ion-button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="tradeHistoryList?.length == 0">
                    <div class="no-data-block">
                      You haven’t tracked any trades for {{ this.symbol }}
                    </div>
                  </div>
                </ion-list>

                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content width-css"
                  *ngIf="segment === 'alert'"
                >
                  <ion-item class="alert-heading-item ion-no-padding width-css">
                    <div class="alert-heading">
                      <div class="page-heading">
                        <h2>Custom Alerts You've Set</h2>
                      </div>
                      <div class="">
                        <ion-button
                          class="color-css1 create-own"
                          fill="outline"
                          (click)="openRightMenuAlert('add')"
                        >
                          <ion-icon name="add-outline" fill="outline"></ion-icon> Add Alert
                        </ion-button>
                        <ion-button
                          color="primary"
                          fill="solid"
                          (click)="openRightMenuNotification()"
                          ><ion-icon name="notifications-outline"></ion-icon>Alert
                          History</ion-button
                        >
                      </div>
                    </div>
                  </ion-item>

                  <ion-row>
                    <ion-segment
                      (ionChange)="segmentChangedalert($event)"
                      [value]="defaultAlertType"
                      class="segmentDesign"
                    >
                      <ion-segment-button value="stockInfoAlert"
                        ><ion-label>
                          Stock Price Alert
                          <span *ngIf="stockPriceAlerts?.length > 0" class="badge">{{
                            stockPriceAlerts?.length
                          }}</span>
                        </ion-label>
                      </ion-segment-button>
                      <ion-segment-button value="dateTimeAlerts"
                        ><ion-label
                          >Date Time Alert
                          <span *ngIf="dateTimeAlerts?.length > 0" class="badge">{{
                            dateTimeAlerts?.length
                          }}</span>
                        </ion-label>
                      </ion-segment-button>
                    </ion-segment>
                    <div class="alert-wrap">
                      <ion-list *ngIf="defaultAlertType == 'stockInfoAlert'">
                        <div class="card-container">
                          <div class="custom-card custom-card-block">
                            <div class="card-content">
                              <div *ngIf="stockPriceAlerts.length > 0">
                                <div
                                  class="product-wrapper alert-product"
                                  *ngFor="let alertList of stockPriceAlerts"
                                >
                                  <div
                                    class="alert-data-section"
                                    [ngClass]="{ 'gray-row': alertList.status !== 'active' }"
                                  >
                                    <div class="alert-left">
                                      <div
                                        *ngIf="alertList.conditionType == 'move_above'"
                                        class="alert-data"
                                        [ngClass]="{ 'green-color': alertList.status === 'active' }"
                                      >
                                        <p>
                                          Price moves above
                                          <span>$ {{ alertList.triggerPrice }}</span>
                                        </p>
                                      </div>
                                      <div
                                        *ngIf="alertList.conditionType == 'move_below'"
                                        class="alert-data"
                                        [ngClass]="{ 'red-color': alertList.status === 'active' }"
                                      >
                                        <p>
                                          Price moves below
                                          <span>$ {{ alertList.triggerPrice }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div class="alerts-right-section">
                                      <ion-item lines="none" class="ion-no-padding width-css">
                                        <ion-toggle
                                          lines="none"
                                          class="ion-no-padding"
                                          justify="end"
                                          [checked]="alertList.status == 'active' ? true : false"
                                          (ionChange)="onAlertCreateSubmit(alertList)"
                                        ></ion-toggle>
                                      </ion-item>
                                      <div class="alerts-btn">
                                        <ion-buttons>
                                          <ion-button
                                            fill="clear"
                                            (click)="toggleDropdown($event, alertList)"
                                          >
                                            <ion-icon name="ellipsis-vertical"> </ion-icon>
                                          </ion-button>
                                        </ion-buttons>
                                        <div
                                          class="dropdown-menu"
                                          *ngIf="isDropdownOpen && selectedRow === alertList"
                                        >
                                          <ion-item
                                            id="watchlist-list-area"
                                            lines="none"
                                            class="ion-no-padding width-css"
                                            (click)="openRightMenuAlert('edit', alertList)"
                                            >Edit Alert</ion-item
                                          >
                                          <ion-item
                                            id="watchlist-list-area"
                                            lines="none"
                                            class="ion-no-padding width-css"
                                            (click)="showAlertDelete(alertList)"
                                            >Delete Alert</ion-item
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="stockPriceAlerts?.length == 0">
                                <div class="no-data-block">
                                  You don’t have any Stock Price Alerts set for {{ symbol }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-list>
                      <ion-list *ngIf="defaultAlertType == 'dateTimeAlerts'">
                        <div class="card-container">
                          <div class="custom-card custom-card-block">
                            <div class="card-content">
                              <div *ngIf="dateTimeAlerts.length > 0">
                                <div
                                  class="product-wrapper alert-product"
                                  *ngFor="let alertDate of dateTimeAlerts"
                                >
                                  <div
                                    class="alert-data-section"
                                    [ngClass]="{ 'gray-row': alertDate.status !== 'active' }"
                                  >
                                    <div class="alert-left">
                                      <div
                                        class="alert-data"
                                        [ngClass]="{ 'green-color': alertDate.status === 'active' }"
                                      >
                                        <p>
                                          Alert set for
                                          {{ alertDate.triggerDate | date : 'MMM d, yyyy,' }} at
                                          {{ alertDate.triggerDate | date : 'h:mm a' }}. ({{
                                            getSystemTimezone()
                                          }})
                                        </p>
                                      </div>
                                    </div>
                                    <div class="alerts-right-section">
                                      <ion-item lines="none" class="ion-no-padding width-css">
                                        <ion-toggle
                                          lines="none"
                                          class="ion-no-padding"
                                          justify="end"
                                          [checked]="alertDate.status == 'active' ? true : false"
                                          (click)="onAlertCreateSubmit(alertDate)"
                                        ></ion-toggle>
                                      </ion-item>
                                      <div class="alerts-btn">
                                        <ion-button
                                          fill="clear"
                                          (click)="toggleDropdown($event, alertDate)"
                                        >
                                          <ion-icon name="ellipsis-vertical"></ion-icon>
                                        </ion-button>
                                        <div
                                          class="dropdown-menu"
                                          *ngIf="isDropdownOpen && selectedRow === alertDate"
                                        >
                                          <ion-item
                                            id="watchlist-list-area"
                                            lines="none"
                                            class="ion-no-padding width-css"
                                            (click)="openRightMenuAlert('edit', alertDate)"
                                            >Edit Alert</ion-item
                                          >
                                          <ion-item
                                            id="watchlist-list-area1"
                                            lines="none"
                                            class="ion-no-padding width-css"
                                            (click)="showAlertDelete(alertDate)"
                                            >Delete Alert</ion-item
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="dateTimeAlerts?.length == 0">
                                <div class="no-data-block">
                                  You don’t have any Date/Time Alerts set for {{ symbol }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-list>
                    </div>
                  </ion-row>

                  <div class="Under-construction" style="display: none">
                    <div>
                      <ion-icon name="construct-outline"></ion-icon>
                      <h1>Under construction</h1>
                    </div>
                  </div>
                </ion-list>
                <ion-list
                  class="animate__animated animate__slideInUp animate__fast tab-content"
                  *ngIf="segment === 'notes'"
                >
                  <ion-item class="ion-no-padding width-css m-0">
                    <ion-title>
                      <div class="page-heading">
                        <h2>Notes</h2>
                      </div>
                    </ion-title>
                    <div class="actions">
                      <div class="crypto-details-button create-own-note">
                        <ion-button
                          size="small"
                          shape="round"
                          class="color-css"
                          fill="solid"
                          (click)="openRightMenuToolNotes('create')"
                        >
                          Create Notes
                        </ion-button>
                      </div>
                    </div>
                  </ion-item>

                  <ion-col>
                    <div class="card-container">
                      <div class="custom-card custom-card-block m-0">
                        <div class="card-content">
                          <div class="product-wrapper">
                            <div>
                              <div class="loading-spinner" *ngIf="isLoading"></div>
                              <div *ngIf="selectedNotes?.length > 0">
                                <div *ngFor="let list of selectedNotes">
                                  <div class="note-money-press">
                                    <div class="list-container-view active">
                                      <div class="current-view">
                                        <div
                                          class="top-box-data"
                                          *ngIf="list?.type == 'weekly' || list?.type == 'long'"
                                        >
                                          <span class="green-lb">{{
                                            list?.type == 'weekly' ? 'Weekly Put' : 'Long Put'
                                          }}</span>
                                          <div class="strip-line">
                                            <div class="text-data">
                                              Expiration:
                                              <span>{{
                                                list.expiration | date : 'MM/dd/yyyy'
                                              }}</span>
                                            </div>
                                            <div class="bullet-cirecl">
                                              <svg
                                                width="4"
                                                height="3"
                                                viewBox="0 0 4 3"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle
                                                  cx="2.07227"
                                                  cy="1.5"
                                                  r="1.5"
                                                  fill="#ACACAC"
                                                />
                                              </svg>
                                            </div>
                                            <div class="text-data bullet">
                                              Strike: <span>${{ list.strike }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="note-icon-date">
                                        <div class="list-data">
                                          <div class="note-info">
                                            {{ list.updatedAt | date : 'MMM d, h:mm a' }}
                                          </div>
                                        </div>
                                        <div class="icon-group">
                                          <div class="edit">
                                            <button
                                              ion-button
                                              icon-only
                                              class="chat-menu-btn"
                                              (click)="openRightMenuToolNotes('edit', list)"
                                            >
                                              <ion-icon name="create-outline"></ion-icon>
                                            </button>
                                          </div>
                                          <div class="delete">
                                            <button
                                              ion-button
                                              icon-only
                                              class="chat-menu-btn"
                                              (click)="deleteNotes(list)"
                                            >
                                              <ion-icon name="trash-outline"></ion-icon>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="lsit-data-wrap">
                                        <div class="list-data">
                                          <div class="alert-info">
                                            {{ list.description }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div *ngIf="selectedNotes?.length == 0">
                                <div class="no-data-block">
                                  You don’t have any notes for {{ symbol }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>

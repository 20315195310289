import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-trade-leg-expiration',
  templateUrl: './trade-leg-expiration.component.html',
  styleUrls: ['./trade-leg-expiration.component.scss'],
})
export class TradeLegExpirationComponent implements OnInit {
  public role = 'user';
  @Input() data;
  @Input() type;
  @Input() slug;
  @Input() toolSlug;
  @Input() productSlug;
  @Input() positionList;
  @Input() stockDetailRow;
  @Input() parentSlug;
  exitPremium: any;
  constructor(
    public modalController: ModalController,
    public router: Router,
    private storageServ: StorageService,
    private toolsService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService
  ) {
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {}
  close() {
    this.modalController.dismiss(null);
  }

  onSubmit() {}

  onUpdateLegs() {
    const today = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    let positionProps: any = {
      mode: 'edit',
      formType: this.type,
      symbol: this.stockDetailRow?.symbol,
      stockPrice: this.stockDetailRow?.stockPrice,
      slug: this.slug,
      componentType: 'positionComponent',
      isPositionExpired: false,
      todayDate: today,
    };
    let slug: any = '';
    slug = this.data.slug;
    if (this.data.parentSlug == '') {
      this.data = this.positionList.filter((x) => x.parentSlug == this.data.slug);
      if (this.data.length > 2) {
        if (this.data.length == 2) {
          this.data = this.positionList[1];
        }
      } else if (this.data.length == 0) {
        this.data = this.positionList.find((x) => x.slug == slug);
      }
    }
    positionProps.positionList = this.positionList;
    positionProps.editData = this.data;
    positionProps.isPositionExpired =
      this.data && this.data.expirationPeriod
        ? new Date(this.data.expirationPeriod).getTime() <= new Date().getTime()
        : true;

    this.menuService.openRightMenu(positionProps);
    this.close();
  }

  createLegs() {
    if (!!this.exitPremium || this.exitPremium !== 0) {
      // let slug = this.positionList.find((x) =>
      //   x.legs.find((x) => x.slug == this.data.slug)
      // )?.parentSlug;
      const payload = {
        positionStatus: 'closed',
        exitPremium: this.exitPremium,
        parentSlug: this.parentSlug,
      };
      this.toolsService.updateExitPremium(this.parentSlug, payload).subscribe((res: any) => {
        if (res) {
          this.menuService.setCloseMenuSubject(payload);
          this.menuController.close('payload');
          this.close();
          this.toastServ.presentToast(res?.message, 'success');
        }
      });
    }
  }
}

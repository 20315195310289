import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-ticker-delete-model',
  templateUrl: './ticker-delete-model.component.html',
  styleUrls: ['./ticker-delete-model.component.scss'],
})
export class TickerDeleteModelComponent implements OnInit {
  public role = 'user';
  @Input() slug;
  @Input() toolSlug;
  @Input() productSlug;
  @Input() id;
  constructor(
    public modalController: ModalController,
    public router: Router,
    private storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private toastServ: ToastService
  ) {
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {}
  close() {
    this.modalController.dismiss(null);
  }

  onSubmit() {
    this.router.navigate([
      `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}`,
    ]);
    this.onCloseAllLegs();
  }

  onCloseAllLegs() {
    this.toolsApi.deleteTrade(this.id).subscribe((response: any) => {
      this.toastServ.presentToast(response.message, 'success');
      this.close();
    });
  }
}

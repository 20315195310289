import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-ticker-details-note-model',
  templateUrl: './ticker-details-note-model.component.html',
  styleUrls: ['./ticker-details-note-model.component.scss'],
  providers: [DatePipe],
})
export class TickerDetailsNoteModelComponent implements OnInit {
  @Input() data;
  @Input() type;
  @Input() mode;
  @Input() parentSlug;
  formTitle: string;
  expirationDate: any;
  formattedUpdatedDate: string;
  noteDes: any;
  @Input() productSlug;
  @Input() toolSlug;
  @Input() slug;
  role: any;
  isNew: boolean;
  notesSlug: any;
  constructor(
    private modalCtrl: ModalController,
    private datePipe: DatePipe,
    private toolsService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storageServ: StorageService,
    private menuService: commonRightMenuService
  ) {
    // this.slug = this.activatedRoute.snapshot.params.tickerSlug;
    // this.productSlug = this.activatedRoute.snapshot.params.productSlug;
    // this.toolSlug = this.activatedRoute.snapshot.params.toolSlug;
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {
    this.noteDes = '';
    this.notesSlug = '';
    if (this.data.expirationPeriod !== undefined) {
      this.expirationDate = this.datePipe.transform(this.data?.expirationPeriod, 'MM/dd/yyyy');
    }
    this.formTitle = this.type === 'long' ? 'Long Put Protection' : 'Weekly Put';
    this.formattedUpdatedDate = this.datePipe.transform(
      this.data?.updatedAt,
      'MMM d, yyyy, h:mm:ss a'
    );
    // this.isNew = !this.data.noteDes ? true : false;
    this.getNotes(this.parentSlug);
    // this.noteDes = this.data?.noteDes;
  }
  close() {
    this.modalCtrl.dismiss();
    this.isNew = false;
    this.noteDes = '';
  }
  updateNotes() {
    let payload: any;
    payload = {
      tickerSlug: this.slug,
      description: this.noteDes,
      parentSlug: this.parentSlug,
      type: this.type === 'long' ? 'long' : this.type == 'weekly' ? 'weekly' : 'overall',
      expiration: this.data?.expirationPeriod,
      strike: this.type === 'long' ? this.data?.strikeBought : this.data?.strikeSold,
    };
    if (this.isNew) {
      this.toolsService.createLegNotes(payload).subscribe((res: any) => {
        if (res) {
          this.close();
          this.data.noteDes = this.noteDes;
          this.menuService.setCloseMenuSubject(this.data);
          this.toastServ.presentToast(res?.message, 'success');
          this.router.navigate([
            `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}/ticker/${this.slug}`,
          ]);
        }
      });
    } else {
      if (this.noteDes.trim() == '') {
        this.toolsService.deleteNote(this.notesSlug).subscribe((response: any) => {
          this.close();
          this.data.noteDes = this.noteDes;
          this.menuService.setCloseMenuSubject(this.data);
          this.toastServ.presentToast('Note Updated Sucessfully.', 'success');
          this.router.navigate([
            `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}/ticker/${this.slug}`,
          ]);
        });
      } else {
        this.toolsService.updateLegNotes(this.notesSlug, payload).subscribe((res: any) => {
          if (res) {
            this.close();
            this.data.noteDes = this.noteDes;
            this.menuService.setCloseMenuSubject(this.data);
            this.toastServ.presentToast(res?.message, 'success');
            this.router.navigate([
              `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}/ticker/${this.slug}`,
            ]);
          }
        });
      }
    }
  }
  getNotes(parentSlug) {
    let notes: any;
    this.toolsService.getLegNotes(parentSlug).subscribe((res1: any) => {
      if (res1.data.length > 0) {
        let i = res1.data.length - 1;
        notes = res1.data[i];
        this.noteDes = notes?.description;
        this.isNew = !notes?.description ? true : false;
        this.notesSlug = notes?.slug;
      } else {
        this.noteDes = '';
        this.notesSlug = '';
        this.isNew = true;
      }
    });
  }
}

<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to alerts </a>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>

<ng-container>
  <div class="page-heading">
    <h2>{{ name }} ({{ symbol }}) Alerts History</h2>
  </div>
  <ion-segment [(ngModel)]="selectedSegment"> </ion-segment>
  <ng-container *ngIf="selectedSegment === 'active'">
    <div *ngFor="let group of CreatedDateData" class="group-container">
      <div class="group-date">
        <h3>{{ getFormattedDate(group.createdDate) }}</h3>
      </div>
      <div class="list-item-block">
        <div *ngFor="let list of group.alerts" class="list-container">
          <div class="list-view" (click)="showRecentsAlerts(list)">
            <div class="list-data list-data-custom" *ngIf="list.alertType != 'on_date'">
              <div class="symbol">
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11C1 9.89543 1.89543 9 3 9V9C4.10457 9 5 9.89543 5 11V14C5 15.1046 4.10457 16 3 16V16C1.89543 16 1 15.1046 1 14V11ZM7.99999 8C7.99999 6.89543 8.89542 6 9.99999 6V6C11.1046 6 12 6.89543 12 8V17C12 18.1046 11.1046 19 9.99999 19V19C8.89542 19 7.99999 18.1046 7.99999 17V8Z"
                    stroke="#30362B"
                    stroke-width="1.2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0002 21V19"
                    stroke="#30362B"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.0002 7C15.0002 5.89543 15.8957 5 17.0002 5V5C18.1048 5 19.0002 5.89543 19.0002 7V7.5C19.0002 8.60456 18.1048 9.49999 17.0002 9.49999V9.49999C15.8957 9.49999 15.0002 8.60457 15.0002 7.5V7Z"
                    stroke="#30362B"
                    stroke-width="1.2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.00061 8.99999V4M17.0006 16V9.49999M17.0006 4.99999V1"
                    stroke="#30362B"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="right-data-block">
                <div class="title">
                  Price crossed<span>$ {{ list.alertPrice }}</span>
                </div>
                <div class="alert-info">
                  {{ list.triggerDate | date : 'MMMM d, y h:mm a' }} ({{ getSystemTimezone() }})
                </div>
              </div>
              <div class="msg">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.47403 15.5727L8.46522 15.5743L8.40842 15.6012L8.39242 15.6042L8.38122 15.6012L8.32442 15.5743C8.31589 15.5717 8.30949 15.573 8.30522 15.5781L8.30202 15.5858L8.28842 15.9148L8.29242 15.9302L8.30042 15.9402L8.38362 15.9971L8.39562 16.0001L8.40522 15.9971L8.48842 15.9402L8.49802 15.9279L8.50122 15.9148L8.48762 15.5866C8.48549 15.5784 8.48096 15.5737 8.47403 15.5727ZM8.68602 15.4859L8.67562 15.4874L8.52762 15.5589L8.51962 15.5666L8.51722 15.575L8.53162 15.9056L8.53562 15.9148L8.54203 15.9202L8.70282 15.9917C8.71296 15.9942 8.72069 15.9922 8.72602 15.9855L8.72922 15.9748L8.70202 15.5028C8.69936 15.4935 8.69402 15.4879 8.68602 15.4859ZM8.11402 15.4874C8.1105 15.4853 8.10629 15.4847 8.10226 15.4855C8.09824 15.4864 8.09471 15.4887 8.09242 15.492L8.08762 15.5028L8.06042 15.9748C8.06096 15.984 8.06549 15.9901 8.07402 15.9932L8.08602 15.9917L8.24682 15.9202L8.25482 15.914L8.25802 15.9056L8.27162 15.575L8.26922 15.5658L8.26122 15.5581L8.11402 15.4874Z"
                    fill="#30362B"
                  />
                  <path
                    d="M13.6 0C14.2365 0 14.847 0.242968 15.2971 0.675453C15.7471 1.10794 16 1.69451 16 2.30614V9.99327C16 10.6049 15.7471 11.1915 15.2971 11.624C14.847 12.0564 14.2365 12.2994 13.6 12.2994H4.2664L1.6 14.2212C0.9408 14.6963 0 14.2443 0 13.4525V2.30614C0 1.69451 0.252856 1.10794 0.702944 0.675453C1.15303 0.242968 1.76348 0 2.4 0H13.6ZM13.6 1.53743H2.4C2.18783 1.53743 1.98434 1.61842 1.83431 1.76258C1.68429 1.90674 1.6 2.10226 1.6 2.30614V12.2994L3.3064 11.0695C3.58335 10.8699 3.92021 10.762 4.2664 10.762H13.6C13.8122 10.762 14.0157 10.681 14.1657 10.5368C14.3157 10.3927 14.4 10.1972 14.4 9.99327V2.30614C14.4 2.10226 14.3157 1.90674 14.1657 1.76258C14.0157 1.61842 13.8122 1.53743 13.6 1.53743ZM7.2 6.91842C7.4039 6.91864 7.60003 6.99366 7.74829 7.12816C7.89656 7.26266 7.98579 7.44649 7.99774 7.64208C8.00969 7.83768 7.94346 8.03027 7.81259 8.18052C7.68172 8.33077 7.49608 8.42733 7.2936 8.45047L7.2 8.45585H4.8C4.5961 8.45563 4.39997 8.38061 4.25171 8.24611C4.10344 8.1116 4.01421 7.92778 4.00226 7.73218C3.99031 7.53659 4.05654 7.344 4.18741 7.19375C4.31828 7.0435 4.50392 6.94694 4.7064 6.9238L4.8 6.91842H7.2ZM11.2 3.84357C11.4122 3.84357 11.6157 3.92456 11.7657 4.06872C11.9157 4.21288 12 4.4084 12 4.61228C12 4.81616 11.9157 5.01168 11.7657 5.15584C11.6157 5.3 11.4122 5.38099 11.2 5.38099H4.8C4.58783 5.38099 4.38434 5.3 4.23431 5.15584C4.08429 5.01168 4 4.81616 4 4.61228C4 4.4084 4.08429 4.21288 4.23431 4.06872C4.38434 3.92456 4.58783 3.84357 4.8 3.84357H11.2Z"
                    fill="#30362B"
                  />
                </svg>
              </div>
            </div>

            <div class="list-data list-data-custom date-wrap" *ngIf="list.alertType === 'on_date'">
              <div class="symbol">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1C14.9707 1 19 5.0293 19 10C19 14.9707 14.9707 19 10 19C5.0293 19 1 14.9707 1 10C1 5.0293 5.0293 1 10 1ZM10 2.8C8.09044 2.8 6.25909 3.55857 4.90883 4.90883C3.55857 6.25909 2.8 8.09044 2.8 10C2.8 11.9096 3.55857 13.7409 4.90883 15.0912C6.25909 16.4414 8.09044 17.2 10 17.2C11.9096 17.2 13.7409 16.4414 15.0912 15.0912C16.4414 13.7409 17.2 11.9096 17.2 10C17.2 8.09044 16.4414 6.25909 15.0912 4.90883C13.7409 3.55857 11.9096 2.8 10 2.8ZM10 4.6C10.2204 4.60003 10.4332 4.68096 10.5979 4.82744C10.7627 4.97393 10.8679 5.17577 10.8937 5.3947L10.9 5.5V9.6274L13.3363 12.0637C13.4977 12.2257 13.5914 12.443 13.5984 12.6715C13.6054 12.9001 13.5251 13.1227 13.3739 13.2943C13.2226 13.4658 13.0118 13.5733 12.7842 13.595C12.5565 13.6166 12.3292 13.5509 12.1483 13.411L12.0637 13.3363L9.3637 10.6363C9.22382 10.4963 9.13399 10.3141 9.1081 10.1179L9.1 10V5.5C9.1 5.2613 9.19482 5.03239 9.3636 4.8636C9.53239 4.69482 9.7613 4.6 10 4.6Z"
                    fill="#30362B"
                    stroke="#F8FAF7"
                    stroke-width="0.5"
                  />
                </svg>
              </div>
              <div class="right-data-block">
                <div class="title">Date/Time Alert triggered</div>
                <div class="alert-info">
                  {{ list.triggerDate | date : 'MMMM d, y h:mm a' }} ({{ getSystemTimezone() }})
                </div>
              </div>
              <div class="msg" (click)="toggleView(list)">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.47403 15.5727L8.46522 15.5743L8.40842 15.6012L8.39242 15.6042L8.38122 15.6012L8.32442 15.5743C8.31589 15.5717 8.30949 15.573 8.30522 15.5781L8.30202 15.5858L8.28842 15.9148L8.29242 15.9302L8.30042 15.9402L8.38362 15.9971L8.39562 16.0001L8.40522 15.9971L8.48842 15.9402L8.49802 15.9279L8.50122 15.9148L8.48762 15.5866C8.48549 15.5784 8.48096 15.5737 8.47403 15.5727ZM8.68602 15.4859L8.67562 15.4874L8.52762 15.5589L8.51962 15.5666L8.51722 15.575L8.53162 15.9056L8.53562 15.9148L8.54203 15.9202L8.70282 15.9917C8.71296 15.9942 8.72069 15.9922 8.72602 15.9855L8.72922 15.9748L8.70202 15.5028C8.69936 15.4935 8.69402 15.4879 8.68602 15.4859ZM8.11402 15.4874C8.1105 15.4853 8.10629 15.4847 8.10226 15.4855C8.09824 15.4864 8.09471 15.4887 8.09242 15.492L8.08762 15.5028L8.06042 15.9748C8.06096 15.984 8.06549 15.9901 8.07402 15.9932L8.08602 15.9917L8.24682 15.9202L8.25482 15.914L8.25802 15.9056L8.27162 15.575L8.26922 15.5658L8.26122 15.5581L8.11402 15.4874Z"
                    fill="#30362B"
                  />
                  <path
                    d="M13.6 0C14.2365 0 14.847 0.242968 15.2971 0.675453C15.7471 1.10794 16 1.69451 16 2.30614V9.99327C16 10.6049 15.7471 11.1915 15.2971 11.624C14.847 12.0564 14.2365 12.2994 13.6 12.2994H4.2664L1.6 14.2212C0.9408 14.6963 0 14.2443 0 13.4525V2.30614C0 1.69451 0.252856 1.10794 0.702944 0.675453C1.15303 0.242968 1.76348 0 2.4 0H13.6ZM13.6 1.53743H2.4C2.18783 1.53743 1.98434 1.61842 1.83431 1.76258C1.68429 1.90674 1.6 2.10226 1.6 2.30614V12.2994L3.3064 11.0695C3.58335 10.8699 3.92021 10.762 4.2664 10.762H13.6C13.8122 10.762 14.0157 10.681 14.1657 10.5368C14.3157 10.3927 14.4 10.1972 14.4 9.99327V2.30614C14.4 2.10226 14.3157 1.90674 14.1657 1.76258C14.0157 1.61842 13.8122 1.53743 13.6 1.53743ZM7.2 6.91842C7.4039 6.91864 7.60003 6.99366 7.74829 7.12816C7.89656 7.26266 7.98579 7.44649 7.99774 7.64208C8.00969 7.83768 7.94346 8.03027 7.81259 8.18052C7.68172 8.33077 7.49608 8.42733 7.2936 8.45047L7.2 8.45585H4.8C4.5961 8.45563 4.39997 8.38061 4.25171 8.24611C4.10344 8.1116 4.01421 7.92778 4.00226 7.73218C3.99031 7.53659 4.05654 7.344 4.18741 7.19375C4.31828 7.0435 4.50392 6.94694 4.7064 6.9238L4.8 6.91842H7.2ZM11.2 3.84357C11.4122 3.84357 11.6157 3.92456 11.7657 4.06872C11.9157 4.21288 12 4.4084 12 4.61228C12 4.81616 11.9157 5.01168 11.7657 5.15584C11.6157 5.3 11.4122 5.38099 11.2 5.38099H4.8C4.58783 5.38099 4.38434 5.3 4.23431 5.15584C4.08429 5.01168 4 4.81616 4 4.61228C4 4.4084 4.08429 4.21288 4.23431 4.06872C4.38434 3.92456 4.58783 3.84357 4.8 3.84357H11.2Z"
                    fill="#30362B"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="edit" style="display: none">
            <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
              <ion-icon name="create-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="dataCount === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You haven't had any alerts that have triggered for {{ symbol }} </span>
    </div>
  </ng-container>
</ng-container>

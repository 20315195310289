<div>
  <div class="body-modal">
    <div class="modal-heading">
      <h2>Trade Leg Note</h2>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
    <div class="content-data">
      <div class="top-box-data">
        <span class="green-lb">{{ formTitle }}</span>
        <div class="strip-line">
          <div class="text-data">
            Expiration: <span>{{ expirationDate }}</span>
          </div>
          <div class="bullet-cirecl">
            <svg
              width="4"
              height="3"
              viewBox="0 0 4 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.07227" cy="1.5" r="1.5" fill="#ACACAC" />
            </svg>
          </div>
          <div class="text-data bullet">
            Strike:<span>
              {{ (type === 'long' ? data?.strikeBought : data?.strikeSold) | currency }}</span
            >
          </div>
        </div>
      </div>
      <div class="text-box">
        <!-- <p>
          {{ data?.noteDes }}
        </p> -->
        <textarea class="note-description" [(ngModel)]="noteDes" rows="5" cols="34"></textarea>
      </div>
      <div class="not-sec">
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.22059 12.4836L6.21413 12.4848L6.17244 12.5054L6.16069 12.5077L6.15247 12.5054L6.11078 12.4848C6.10451 12.4828 6.09982 12.4838 6.09668 12.4877L6.09433 12.4936L6.08435 12.7449L6.08729 12.7567L6.09316 12.7643L6.15423 12.8078L6.16304 12.8101L6.17009 12.8078L6.23116 12.7643L6.23821 12.7549L6.24055 12.7449L6.23057 12.4942C6.22901 12.4879 6.22568 12.4844 6.22059 12.4836ZM6.3762 12.4173L6.36857 12.4184L6.25993 12.4731L6.25406 12.4789L6.2523 12.4854L6.26287 12.7379L6.2658 12.7449L6.2705 12.7491L6.38853 12.8037C6.39597 12.8056 6.40165 12.8041 6.40556 12.799L6.40791 12.7908L6.38795 12.4302C6.38599 12.4231 6.38208 12.4188 6.3762 12.4173ZM5.95634 12.4184C5.95375 12.4169 5.95066 12.4164 5.9477 12.417C5.94475 12.4177 5.94216 12.4195 5.94048 12.422L5.93696 12.4302L5.91699 12.7908C5.91738 12.7978 5.92071 12.8025 5.92698 12.8048L5.93578 12.8037L6.05382 12.7491L6.05969 12.7444L6.06204 12.7379L6.07202 12.4854L6.07026 12.4783L6.06439 12.4725L5.95634 12.4184Z"
            fill="#515151"
          />
          <path
            d="M5.87227 0C9.11552 0 11.7445 2.62901 11.7445 5.87227C11.7445 9.11552 9.11552 11.7445 5.87227 11.7445C2.62901 11.7445 0 9.11552 0 5.87227C0 2.62901 2.62901 0 5.87227 0ZM5.87227 1.17445C4.62633 1.17445 3.43142 1.6694 2.55041 2.55041C1.6694 3.43142 1.17445 4.62633 1.17445 5.87227C1.17445 7.11821 1.6694 8.31311 2.55041 9.19412C3.43142 10.0751 4.62633 10.5701 5.87227 10.5701C7.11821 10.5701 8.31311 10.0751 9.19412 9.19412C10.0751 8.31311 10.5701 7.11821 10.5701 5.87227C10.5701 4.62633 10.0751 3.43142 9.19412 2.55041C8.31311 1.6694 7.11821 1.17445 5.87227 1.17445ZM5.87227 2.34891C6.0161 2.34893 6.15492 2.40173 6.2624 2.49731C6.36989 2.59288 6.43856 2.72458 6.45538 2.86743L6.45949 2.93613V5.62916L8.04912 7.21878C8.15444 7.32445 8.21558 7.46626 8.22013 7.61538C8.22469 7.76451 8.17231 7.90978 8.07364 8.02168C7.97496 8.13359 7.83739 8.20374 7.68887 8.21789C7.54035 8.23203 7.39201 8.18912 7.27398 8.09786L7.21878 8.04912L5.4571 6.28744C5.36583 6.19609 5.30722 6.07721 5.29033 5.94919L5.28504 5.87227V2.93613C5.28504 2.78039 5.34691 2.63103 5.45704 2.5209C5.56716 2.41078 5.71653 2.34891 5.87227 2.34891Z"
            fill="#515151"
          />
        </svg>
        <span>Last Modified {{ formattedUpdatedDate }}</span>
      </div>
    </div>
    <div class="footer-modal">
      <ion-button class="close-btn" fill="outline" (click)="close()">Close</ion-button>
      <ion-button class="add-btn" color="primary" fill="solid" (click)="updateNotes()">
        {{ !isNew ? 'Update' : 'Add' }} Note</ion-button
      >
    </div>
  </div>
</div>

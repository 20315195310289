<div>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="body-modal">
    <h2>Leg Expiration Passed</h2>
    <p>
      The expiration date for this leg has passed. Please enter the exit premium to close the
      position.
    </p>
  </div>
  <div class="input-container">
    <ion-input type="number" [(ngModel)]="exitPremium" placeholder="Exit Premium"></ion-input>
  </div>

  <div class="footer-modal">
    <ion-button class="close-btn" fill="outline" (click)="onUpdateLegs()"
      >Update Leg Details</ion-button
    >
    <ion-button type="submit" fill="solid" [disabled]="!exitPremium" (click)="createLegs()"
      >Save & Close Position</ion-button
    >
  </div>
</div>

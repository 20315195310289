<ion-content class="ion-padding" appScrollbar>
  <!-- <div class="general-main" *ngIf="!isToolAccess"> -->
  <!-- <div class="no-data-container"> -->
  <!-- <div class="no-data"> -->
  <!-- <img src="/assets/icon/locked.png" alt="" /> -->
  <!-- <p *ngIf="!isToolAccess && !lockedPageContent"> -->
  <!-- Please be patience, reviewing tool access. -->
  <!-- </p> -->
  <!-- <p -->
  <!-- *ngIf="!isToolAccess && lockedPageContent" -->
  <!-- [innerHTML]="lockedPageContent | keepHtml" -->
  <!-- ></p> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <ol class="cd-breadcrumb custom-separator custom-icons alerts">
    <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
    <li>
      <a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}" class="alerts-text"
        >Alerts</a
      >
    </li>
  </ol>

  <div class="space">
    <div class="page-heading">
      <h2>Active Alerts</h2>
      <div class="actions">
        <div class="alerts-button">
          <ion-button class="color-css1" fill="outline" (click)="onAlertClick()">
            Alert Preferences
          </ion-button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="alertData?.length > 0">
    <ion-accordion-group *ngFor="let alert of alertData">
      <ion-accordion class="alerts-accordian" value="first">
        <ion-item slot="header" color="white">
          <ion-label>
            <div class="company">
              <div class="company-name">{{ alert.symbol }}</div>
              <div class="company-details">{{ alert.ticker }}</div>
            </div>
            <div class="no-alerts">{{ alert.totalCount }} Active Alerts</div>
          </ion-label>
        </ion-item>
        <div class="ion-padding alert-content" slot="content">
          <div class="general-alerts">
            <div class="general-alerts-header">General Alerts</div>
            <div class="general-alerts-list">
              <div class="general-alerts-list-content" (click)="onClickAlert(alert, 'stock_price')">
                <div class="general-alerts-pointer">
                  <div class="pointer"></div>
                  <div class="data">Stock Price Alerts</div>
                </div>
                <div class="general-alerts-no">{{ alert.generalAlerts.stockPrice }}</div>
              </div>
              <div class="general-alerts-list-content" (click)="onClickAlert(alert, 'on_date')">
                <div class="general-alerts-pointer">
                  <div class="pointer"></div>
                  <div class="data">Date/Time Alerts</div>
                </div>
                <div class="general-alerts-no">{{ alert.generalAlerts.onDate }}</div>
              </div>
            </div>
          </div>
          <div class="trade-tracker-alerts">
            <div class="trade-tracker-alerts-header">Trade Tracker Alerts</div>
            <div class="trade-tracker-alerts-list">
              <div class="trade-tracker-alerts-list-content">
                <div class="trade-tracker-alerts-pointer">
                  <div class="pointer"></div>
                  <div class="data">Money Press Method</div>
                </div>
                <div class="trade-tracker-alerts-no">
                  {{ alert.tradeTrackerAlert.moneyPressMethod }}
                </div>
              </div>
              <div class="trade-tracker-alerts-list-content">
                <div class="trade-tracker-alerts-pointer">
                  <div class="pointer"></div>
                  <div class="data">Call Spread</div>
                </div>
                <div class="trade-tracker-alerts-no">{{ alert.tradeTrackerAlert.callSpread }}</div>
              </div>
              <div class="trade-tracker-alerts-list-content">
                <div class="trade-tracker-alerts-pointer">
                  <div class="pointer"></div>
                  <div class="data">MIT</div>
                </div>
                <div class="trade-tracker-alerts-no">{{ alert.tradeTrackerAlert.mit }}</div>
              </div>
            </div>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</ion-content>

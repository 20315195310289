import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
@Component({
  selector: 'app-recents-alerts',
  templateUrl: './recents-alerts.component.html',
  styleUrls: ['./recents-alerts.component.css'],
})
export class RecentsAlertsComponent implements OnInit {
  @Input() alertData: string;
  @Input() list: string;
  @Input() activeToolSlug: string;
  recentData: any;
  role: any;
  isHistory: boolean = false;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public storageServ: StorageService,
    private toolsApi: ToolServiceService
  ) {
    this.role = this.storageServ.get('role');
  }

  ngOnInit() {
    if (this.list) {
      this.isHistory = true;
      this.recentData = this.list;
    }
    if (this.alertData) {
      this.isHistory = false;
      this.recentData = this.alertData;
    }
  }

  getSystemTimezone(): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  }
  onSymbolWiseView(symbol, segmentValue: string = 'alert', alertType: string) {
    let activeToolSlug: any = 'r6VAKez';
    this.toolsApi.setSegmentValue(segmentValue);
    this.toolsApi.setAlertValue(alertType);
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
    this.close();
  }

  close() {
    this.modalController.dismiss(null);
  }
}
